import { FC, useState, useEffect, useRef } from "react";
import { Button, CssBaseline, Grid } from '@material-ui/core';
import { styleCommon } from './styleCommon';
import { authentication } from "../_actions/authentication";
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import loadingImg from '../assets/images/preloader.gif';
import logo from '../assets/images/Logo.png';
import { Footer } from '../layouts/Footer';
import { LogoutButton } from "../_components/LogoutButton";

export const HomePage: FC<any> = () => {
  const cashierDeviceStorage = localStorage.getItem('cashier');
  const classes = styleCommon();
  const [t] = useTranslation();
  const btnHomeOrder = useRef<HTMLButtonElement>(null);
  const [authCode, setAuthCode] = useState<string>("");
  const [cashierDevice, setCashierDevice] = useState<string>(cashierDeviceStorage ? cashierDeviceStorage : 'CAJA4');
  const [isSetApi, setIsSetApi] = useState<boolean>(false);
  const query = new URLSearchParams(useLocation().search);
  const isAuthenticated = authentication.currentAdminTokenValue ? true : false;
  
  useEffect(() => {
    if (query.get('cashier')) {
      setCashierDevice(query.get('cashier') || 'CAJA1');
    }

    localStorage.setItem('cashier', cashierDevice);

    if (query.get('code')) {
      setAuthCode(query.get('code') || '');
    } else {
      if (!isAuthenticated) {
        window.location.href = "/login";
      }
    }
    
    if (btnHomeOrder.current) {
      btnHomeOrder.current.focus();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  useEffect(() => {
    if (btnHomeOrder.current) {
      btnHomeOrder.current.focus();
    }
  });

  useEffect(() => {
    localStorage.setItem('cashier', cashierDevice);
    setBaseApiUrl(cashierDevice);
  }, [cashierDevice]);

  useEffect(() => {
    if (authCode !== "") {
      checkAuth(authCode);
    }
  }, [authCode]);

  const checkAuth = async (pin: string) => {
    console.log('checkAuth llamado con pin:', pin);
    const res = await authentication.getAuthAPIPin(pin);
    console.log('Resultado de la autenticación:', res);
  }

  const setBaseApiUrl = async (cashier: string) => {
    console.log('setBaseApiUrl llamado con cashier:', cashier);
    await authentication.setBaseApiUrl(cashier);
    setIsSetApi(true);
  }

  const handleNewOrder = () => {
    localStorage.removeItem("products");
    localStorage.removeItem("cart_total");
    localStorage.removeItem("cart_sub_total");
    localStorage.removeItem("cart_total_discount");
    localStorage.removeItem("coupon_code");
    localStorage.setItem('orders', '');
    localStorage.setItem('products_custom_price', '');
    localStorage.setItem('products_new_created', '');
    localStorage.setItem('auth_key', '');
    window.location.href = '/scanner';
  }

  return (
    (isAuthenticated && isSetApi) ? (
      <div className={classes.root}>
        <CssBaseline />
        <LogoutButton />
        <main className={classes.content}>
          <Grid container className="main_content">
            <Grid container>
              <Grid item xs={12} className="t_align_center">
                <img src={logo} alt={t("common.main_title")} />
                <p className="welcome_text">
                  {t('home.welcome_text1')} <br />
                  {t('home.welcome_text2')}
                </p>
                <Button
                  ref={btnHomeOrder}
                  className="btn_home_order"
                  type="button" variant="contained"
                  onClick={handleNewOrder} 
                >
                  {t('common.btn_new_order')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </main>
        <Footer />
      </div>
    ) : (
      <div className="loading-content">
        <img src={loadingImg} alt="" />
      </div>
    )
  );
};

export default HomePage;
