export const en_lang = {
  "common": {
    "main_title": "Don Eduardo P.O.S",
    "title_dashboard": "Panel",
    "title_admins": "Administradores",
    "title_lands": "Tierras",
    "title_users": "Usuarios",
    "title_rooms": "Salas",
    "title_servers": "Servidores",
    "text_action": "Acción",
    "text_modal_delete": "¿Aún deseas eliminar?",
    "text_selected": "seleccionado(s)",
    "text_search": "Buscar",
    "btn_save": "Guardar",
    "btn_create": "Crear",
    "btn_edit": "Editar",
    "btn_delete": "Eliminar",
    "btn_cancel": "Cancelar",
    "btn_upload": "Subir",
    "btn_login": "Iniciar sesión",
    "btn_connect": "Conectar",
    "btn_deactivate": "Desactivar",
    "btn_activate": "Activar",
    "btn_edit_info": "Editar información",
    "btn_block": "Bloquear",
    "btn_confirm": "Confirmar",
    "btn_back": "Atrás",
    "btn_next": "Siguiente",
    "menu_item_acc_info": "Información de la cuenta",
    "menu_item_logout": "Cerrar sesión",
  },
  "admin" : {
    "page_title": "Gestión de Administradores",
    "text_name": "Nombre",
    "text_username": "Nombre de usuario",
    "text_password": "Contraseña",
    "text_password_confirm": "Confirmar contraseña",
    "text_current_admin": "Administrador actual",
  },
  "dashboard": {
    "text_user": "usuario(s)",
    "btn_land": "Ir a la página de Tierras",
    "btn_user": "Ir a la página de Usuarios"
  },
  "land" : {
    "page_title": "Gestión de Tierras",
    "page_server_title": "Información del Servidor",
    "text_name": "Nombre",
    "text_display_name": "Nombre para mostrar",
    "text_name_land": "Nombre de la Tierra",
    "text_name_room": "Nombre de la Sala",
    "text_description": "Descripción",
    "text_description_land": "Descripción de la Tierra",
    "text_description_room": "Descripción de la Sala",
    "text_scenario_port": "Puerto del Escenario",
    "text_scenario_client": "Cliente del Escenario",
    "text_scenario_server": "Servidor del Escenario",
    "text_limit_user": "Límite de Usuarios",
    "text_limit_server": "Límite de Servidores",
    "text_number": "Número",
    "text_image": "Imagen",
    "text_create_server": "Crear Servidor",
    "text_auto_scale": "Escalado Automático",
    "text_scale_up": "Escalado hacia arriba",
    "text_scale_down": "Escalado hacia abajo",
    "text_thumbnail": "Miniatura",
    "text_no_land": "Sin Tierra",
    "text_no_server": "Sin Servidor",
    "text_no_server_info": "Esta tierra no tiene ningún servidor en este momento.",
    "text_server_state": "Estado del Servidor",
    "text_instance_id": "ID de la Instancia",
    "text_upload_successfully": "¡ARCHIVO CARGADO EXITOSAMENTE!",
    "btn_upload_scenario": "Subir Escenario",
    "btn_detail_info": "Información Detallada",
    "btn_server_info": "Información del Servidor",
    "btn_server_create": "Crear Servidor",
    "btn_server_stop": "Detener Servidor",
    "btn_server_start": "Iniciar Servidor",
    "info_server_creating": "Creando el Servidor...",
    "info_server_created": "¡El Servidor ha sido creado!",
    "info_server_checking_status": "Verificando el estado del servidor...",
    "info_server_running": "¡El Servidor está funcionando ahora!",
    "info_server_stopping": "Deteniendo el Servidor...",
    "info_server_stopped": "¡El Servidor se ha detenido!",
    "info_server_starting" : "Iniciando el Servidor...",
    "text_server_running": "¡Este Servidor está en funcionamiento!",
    "text_server_stopped": "¡Este Servidor se encuentra detenido!",
    "text_server_pending": "Este Servidor está cambiando de estado...",
    "error_server_create": "Se produjo un error al crear el servidor.",
    "error_server_stop": "Se produjo un error al detener el servidor.",
    "error_server_start": "Se produjo un error al iniciar el servidor.",
    "server_info": {
      "label_image": "Imagen",
      "label_instance_id": "ID de la Instancia",
      "label_state": "Estado",
      "label_instance_type": "Tipo de Instancia",
      "label_image_id": "ID de la Imagen",
      "label_key_name": "Nombre de la Clave",
      "label_launch_time": "Tiempo de Inicio",
      "label_owner_id": "ID del Propietario",
      "label_public_ip_address": "Dirección IP Pública",
      "label_public_dns_address": "Dirección DNS Pública",
      "label_private_ip_address": "Dirección IP Privada",
      "label_private_dns_address": "Dirección DNS Privada",
      "label_vpc_id": "ID de VPC",
      "label_subnet_id": "ID de Subred",
      "label_availability_zone": "Zona de Disponibilidad",
      "label_root_device_name": "Nombre del Dispositivo Raíz",
      "label_root_device_type": "Tipo de Dispositivo Raíz",
      "label_cpu": "Número de vCPUs",
      "label_architecture": "Arquitectura",
      "label_virtualization_type": "Tipo de Virtualización",
    },
  },
  "room" : {
    "text_room_list" : "Lista de Salas",
    "text_parent_room": "Sala Padre",
    "text_none": "Ninguno",
    "text_no_room": "No hay salas en esta tierra.",
  },
  "user": {
    "page_title": "Gestión de Usuarios",
    "text_username": "Nombre de usuario",
    "text_user_id": "ID de Usuario",
    "text_status": "Estado",
    "text_email": "Dirección de Email",
    "text_ticket_code": "Código de Ticket",
    "text_change_status": "¿Cambiar estado?",
    "text_delete_user": "¿Quieres eliminar al usuario?",
  },
  "error": {
    "required_name": "¡Nombre es obligatorio!",
    "required_display_name": "¡El Nombre para mostrar es obligatorio!",
    "required_username": "¡El Nombre de usuario es obligatorio!",
    "required_password": "¡La Contraseña es obligatoria!",
    "required_password_confirm": "¡La Confirmación de la Contraseña es obligatoria!",
    "required_email": "¡El Email es obligatorio!",
    "required_limit_user": "¡El Límite de Usuarios es obligatorio!",
    "required_limit_server": "¡El Límite de Servidores es obligatorio!",
    "required_limit_number": "¡El Número Límite es obligatorio!",
    "required_scale_up": "¡El Escalado hacia arriba es obligatorio!",
    "required_scale_down": "¡El Escalado hacia abajo es obligatorio!",
    "email_format": "¡Por favor, ingresa un formato de email correcto!",
    "password_length": "¡La longitud de la contraseña debe ser al menos de 6 caracteres!",
    "password_same": "Ambas contraseñas deben ser iguales",
    "limit_user_min": "El Límite de Usuarios debe ser mayor a 0",
    "limit_server_min": "El Límite de Servidores debe ser mayor a 0",
    "limit_number_min": "El Número Límite debe ser mayor a 0",
    "scale_up_min": "El Escalado hacia arriba debe ser mayor a 0",
    "scale_down_min": "El Escalado hacia abajo debe ser mayor a 0",
  }
}