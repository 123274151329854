import React, { FC } from 'react';
import { authentication } from "../_actions/authentication";
import { Button } from '@material-ui/core';

export const LogoutButton: FC<any> = () => {
  const cashierDeviceStorage = localStorage.getItem('cashier') ? localStorage.getItem('cashier') : "CAJA4";

  document.addEventListener('keydown', (event) => {
    if (event.ctrlKey && event.key === 'F2') {
      handleLogout();
    }
  });

  const handleLogout = () => {
    authentication.logout();
    window.location.href = '/?cashier=' + cashierDeviceStorage;
  }

  return (
    <React.Fragment>
      <Button
        className="btn_logout"
        type="button" variant="contained"
        onClick={() => handleLogout()}
      >
        Cerrar
      </Button>
    </React.Fragment>
  );
}
