import axios from "axios";
import {
  BaseAPIUrl, PosnetAPIUrl,
  CloverAPIUrl, CloverAppMerchant, CloverAccessToken,
  MercadoCollectorID, MercadoStore, MercadoAccessToken
} from "../utils/apiUtil";

const baseApiUrl = BaseAPIUrl;
const currentAdminToken = localStorage.getItem('admin_token');
const employeeNameStorage = localStorage.getItem('employee_name');
const employeeIdStorage = localStorage.getItem('employee_id');
const cashierStorage = localStorage.getItem('cashier');
const couponCodeStorage = localStorage.getItem('coupon_code') ? localStorage.getItem('coupon_code') : '';
const cartTotalDiscountStorage = localStorage.getItem('cart_total_discount') ? localStorage.getItem('cart_total_discount') : '';
const cartSubTotalStorage = localStorage.getItem('cart_sub_total') ? localStorage.getItem('cart_sub_total') : '';
const serverAPIUrl = localStorage.getItem('server_api_url') ? localStorage.getItem('server_api_url') : PosnetAPIUrl;
const tuWalletApiUrl = "https://app.tuwallet.com.ar/api/";

const createOrderForDB = async (
  productsData: any,
  total: number,
  paymentMethod: string,
  customerId = '',
  receiptNumber = '',
  serialNumber = '',
  securityCode = ''
) => {
  let orderId: any;
  let totalDiscount = 0;
  let cartSubTotal = 0;

  if (cartTotalDiscountStorage) {
    totalDiscount = parseFloat(cartTotalDiscountStorage);
  }

  if (cartSubTotalStorage) {
    cartSubTotal = parseFloat(cartSubTotalStorage);
  }

  let params = {
    products_data: JSON.stringify(productsData),
    cart_total: total,
    cart_sub_total: cartSubTotal,
    payment_method: paymentMethod,
    customer_id: customerId,
    receipt_number: receiptNumber,
    employee_id: employeeIdStorage,
    employee_name: employeeNameStorage,
    cashier: cashierStorage,
    coupon: couponCodeStorage,
    total_discount: totalDiscount,
    serial_number: serialNumber,
    security_code: securityCode
  };

  const config = {
    headers: {
      'content-type': 'application/json'
    },
    params: params,
  }

  try {
    const response = await axios.post(baseApiUrl + "order/create", config);
    if (response && response.status === 201) {
      orderId = response.data;

      // Después de crear la orden, enviar los datos a TuWallet
      await sendOrderToTuWallet({
        order_id: orderId,
        customer_id: customerId,
        serial_number: serialNumber,
        purchase_datetime: new Date().toISOString(),
        cashier_name: employeeNameStorage,
        store_name: BaseAPIUrl.includes('doneduardomarket') ? 'DON EDUARDO' : 'TU SUPER',
        total_amount: total
      });
    }
  } catch (e) {
    if (e instanceof Error) {
      console.log("Error al crear la orden:", e.message);
    } else {
      console.log("Error desconocido:", e);
    }
  }

  return orderId;
}

const sendOrderToTuWallet = async (orderData: any) => {
  try {
    const response = await axios.post(tuWalletApiUrl + "customer/storeOrder", orderData);
    if (response && response.status === 201) {
      console.log("Orden enviada a TuWallet exitosamente");
    }
  } catch (e) {
    if (e instanceof Error) {
      console.log("Error al enviar la orden a TuWallet:", e.message);
    } else {
      console.log("Error desconocido al enviar la orden a TuWallet:", e);
    }
  }
};

const deleteOrderForDB = async (orderId: number) => {
  let status = false;
  const params = {
    order_id: orderId
  }

  try {
    const response = await axios.get(baseApiUrl + "order/delete", { params });
    if (response && response.status === 200) {
      status = true;
    }
  } catch (e) {
    console.log((e as any).message);
  }

  return status;
}

const createCloverOrder = async (productsData: any, total: number, paymentMethod: string): Promise<any> => {
  let data: any;

  if (currentAdminToken) {
    try {
      const products = productsData.map((item: any) => {
        return {
          printed: 'false',
          exchanged: 'false',
          refunded: 'false',
          refund: { transactionInfo: { isTokenBasedTx: 'false', emergencyFlag: 'false' } },
          isRevenue: 'false',
          name: item.name,
          price: parseFloat(item.price),
          unitQty: parseInt(item.quantity)
        }
      });

      const options = {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: 'Bearer ' + CloverAccessToken
        },
        body: JSON.stringify({
          orderCart: {
            lineItems: products,
          }
        })
      };

      const response = await fetch(CloverAPIUrl + 'v3/merchants/' + CloverAppMerchant + '/atomic_order/orders', options);

      if (response && response.status === 200) {
        data = await response.json();
      }
    } catch (e) {
      console.log((e as any).message);
    }

    if (data && data.id) {
      // await createOrderForDB(productsData, total, paymentMethod)
    }
  }

  return data;
}

const updateCloverOrder = async (orderId: string, total: number, employeeId: string | null): Promise<any> => {
  let data = false;
  if (currentAdminToken) {
    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: 'Bearer ' + CloverAccessToken
      },
      body: JSON.stringify({
        employee: { id: employeeId },
        orderType: {
          taxable: 'false',
          isDefault: 'false',
          filterCategories: 'false',
          isHidden: 'false',
          isDeleted: 'false'
        },
        taxRemoved: 'false',
        merchant: { id: CloverAppMerchant },
        currency: 'ARS',
        total: total
      })
    };

    const response = await fetch(CloverAPIUrl + 'v3/merchants/' + CloverAppMerchant + '/orders/' + orderId, options);

    if (response && response.status === 200) {
      data = true;
    }
  }

  return data;
}

const createCloverPayment = async (orderId: string, amount: number, tenderId: string, employeeId: string | null): Promise<any> => {
  let data: any;
  if (currentAdminToken) {
    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: 'Bearer ' + CloverAccessToken
      },
      body: JSON.stringify({
        order: { id: orderId },
        tender: { id: tenderId },
        employee: { id: employeeId },
        offline: 'false',
        transactionSettings: {
          disableCashBack: 'false',
          cloverShouldHandleReceipts: 'true',
          forcePinEntryOnSwipe: 'false',
          disableRestartTransactionOnFailure: 'false',
          allowOfflinePayment: 'false',
          approveOfflinePaymentWithoutPrompt: 'false',
          forceOfflinePayment: 'false',
          disableReceiptSelection: 'false',
          disableDuplicateCheck: 'false',
          autoAcceptPaymentConfirmations: 'false',
          autoAcceptSignature: 'false',
          returnResultOnTransactionComplete: 'false',
          disableCreditSurcharge: 'false'
        },
        transactionInfo: { isTokenBasedTx: 'false', emergencyFlag: 'false' },
        amount: amount * 100
      })
    };

    const response = await fetch(CloverAPIUrl + 'v3/merchants/' + CloverAppMerchant + '/orders/' + orderId + '/payments', options);

    if (response && response.status === 200) {
      data = await response.json();
    }
  }

  return data;
}

const getCloverTenders = async (): Promise<any> => {
  let data: any;
  try {
    const options = {
      method: 'GET',
      headers: {
        accept: 'application/json',
        authorization: 'Bearer ' + CloverAccessToken
      }
    };

    await fetch(CloverAPIUrl + 'v3/merchants/' + CloverAppMerchant + '/tenders', options)
      .then(response =>
        response.json()
      )
      .then(response => {
        if (response.elements && response.elements.length > 0) {
          let count = 0;
          data = response.elements.filter((item: any) => item.labelKey === 'com.clover.tender.cash').map((item: any) => {
            const itemData = {
              ...item,
              index: count,
            }
            count++;
            return itemData;
          });
        }
      })
      .catch(err => console.error(err));
  } catch (e) {
    console.log((e as any).message);
  }

  return data;
}

const checkoutMercadoPayment = async (orderId: number, productsData: any, total: number): Promise<any> => {
  let data: any;

  const config = {
    collectorID: MercadoCollectorID,
    store: MercadoStore,
    posID: localStorage.getItem('mp_pos_id'),
    accessToken: MercadoAccessToken,
    orderId: orderId,
    productsData: productsData,
    total: total,
  };

  try {
    const options = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      body: JSON.stringify(config)
    };
    const response = await fetch(serverAPIUrl + 'mercadoCheckout', options);

    if (response && response.status === 200) {
      data = await response.json();
    }
  } catch (e: any) {
    console.log(e)
  }

  return data;
}

const getMercadoOrderStatus = async (orderId: number): Promise<any> => {
  let data: any;

  const config = {
    accessToken: MercadoAccessToken,
    orderId: orderId,
  };

  try {
    const options = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      body: JSON.stringify(config)
    };
    const response = await fetch(serverAPIUrl + 'mercadoOrderStatus', options);

    if (response && response.status === 200) {
      data = await response.json();
    }
  } catch (e: any) {
    console.log(e)
  }

  return data;
}

const changeMercadoPaymentStatus = async (orderId: number): Promise<any> => {
  let data: any;

  const config = {
    orderId: orderId,
    accessToken: MercadoAccessToken
  };

  try {
    const options = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      body: JSON.stringify(config)
    };
    const response = await fetch(serverAPIUrl + 'mercadoPaymentStatus', options);

    if (response && response.status === 200) {
      data = await response.json();
    }
  } catch (e: any) {
    console.log(e)
  }

  return data;
}

const getCustomerFromCard = async (serialNumber: string, securityCode: string) : Promise<any> => {
  let data: any;

  const params = {
    serial_number: serialNumber,
    security_code: securityCode
  }

  try {
    const response = await axios.get(tuWalletApiUrl + "customer/getFromCard", { params });

    if (response) {
      data = response.data;
    }
  } catch (e) {
    console.log((e as any).message);
  }

  return data;
}

const getVoucher = async (code: string): Promise<any> => {
  let data: any;

  const params = {
    code: code,
  }

  try {
    const response = await axios.get(baseApiUrl + "order/getVoucher", { params });

    if (response) {
      data = response.data;
    }
  } catch (e) {
    console.log((e as any).message);
  }

  return data;
}
const saveCashTransaction = async (orderId: number, cashAmount: number, changeAmount: number) => {
  const params = {
    order_id: orderId,
    cash_amount: cashAmount,
    change_amount: changeAmount
  };

  try {
    const response = await axios.post(`${BaseAPIUrl}/order/saveCashTransaction`, params);
    if (response.status === 200) {
      console.log("Transacción en efectivo guardada correctamente.");
    }
  } catch (e) {
    console.error("Error al guardar la transacción en efectivo:", e);
  }
}

export const orderAction = {
  createOrderForDB,
  deleteOrderForDB,
  createCloverOrder,
  createCloverPayment,
  getCloverTenders,
  updateCloverOrder,
  checkoutMercadoPayment,
  getMercadoOrderStatus,
  changeMercadoPaymentStatus,
  getCustomerFromCard,
  getVoucher,
  saveCashTransaction
};
