import React, { FC, useState, useEffect, useRef } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useTranslation } from 'react-i18next';

interface Data {
  id: number;
  quantity: number;
  name: string;
  price: number;
  total: number;
}

interface HeadCell {
  align: 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined;
  id: keyof Data;
  label: string;
}

function EnhancedTableHead(props: any) {
  const { hideId } = props;
  const [t] = useTranslation();

  let headCells: HeadCell[] = [];

  if (!hideId) {
    headCells = [
      { id: 'id', align: 'center', label: `${t("product.product_id")}` },
      { id: 'name', align: 'center', label: `${t("product.name")}` },
      { id: 'quantity', align: 'center', label: `${t("product.quantity")}` },
      { id: 'price', align: 'right', label: `${t("product.price")}` },
      { id: 'total', align: 'right', label: `${t("product.total")}` },
    ];
  } else {
    headCells = [
      { id: 'name', align: 'center', label: `${t("product.name")}` },
      { id: 'quantity', align: 'center', label: `${t("product.quantity")}` },
      { id: 'price', align: 'right', label: `${t("product.price")}` },
      { id: 'total', align: 'right', label: `${t("product.total")}` },
    ];
  }

  return (
    <TableHead>
      <TableRow className="tbl_product_head">
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            variant="head"
            align={headCell.align}
            className="pr-10 sticky_th"
          >
            <strong>{headCell.label}</strong>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
export const ProductList: FC<any> = (props) => {
  const { products, hideId } = props;
  const [t] = useTranslation();
  const hideIdCol = hideId ? hideId : false;
  let productIndex = 0;

  const [selectedProductIndex, setSelectedProductIndex] = useState<number | null>(null);
  const rowRefs = useRef<(HTMLTableRowElement | null)[]>([]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowDown':
          if (selectedProductIndex === null || selectedProductIndex < products.length - 1) {
            setSelectedProductIndex((prev) => (prev !== null ? prev + 1 : 0));
          }
          break;
        case 'ArrowUp':
          if (selectedProductIndex !== null && selectedProductIndex > 0) {
            setSelectedProductIndex((prev) => prev! - 1);
          }
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedProductIndex, products.length]);

  useEffect(() => {
    if (selectedProductIndex !== null && rowRefs.current[selectedProductIndex]) {
      rowRefs.current[selectedProductIndex]?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [selectedProductIndex]);

  return (
    <div className="product_list_container">
      <TableContainer className="tbl_product_container">
        <Table className="tbl_product" stickyHeader aria-label="sticky table">
          <EnhancedTableHead hideId={hideIdCol} />
          <TableBody>
            {products ? products.map((row: any, index: number) => {
              productIndex++;
              const productKey = row.id + productIndex;
              return (
                <TableRow 
                  key={productKey} 
                  className={`tbl_product_row ${selectedProductIndex === index ? 'selectedProduct' : ''}`}
                  ref={(el) => rowRefs.current[index] = el}
                >
                  {!hideIdCol && (
                    <TableCell align="center">{row.id}</TableCell>
                  )}
                  <TableCell align="center">{row.name}</TableCell>
                  <TableCell align="center">{row.quantity}</TableCell>
                  <TableCell align="right" className="pr-10">${row.price}</TableCell>
                  {row.orgTotal && (
                    <TableCell align="right" className="pr-10"><s>${row.orgTotal}</s> ${row.total}</TableCell>
                  ) || (
                    <TableCell align="right" className="pr-10">${row.total}</TableCell>
                  )}
                </TableRow>
              );
            }) : (
              <div className='empty_data'>
                {t('product.empty_data')}
              </div>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}