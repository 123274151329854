import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { en_lang } from "../i18n/en";
import { es_lang } from "../i18n/es";

const resources = {
  en: {
    translation: en_lang
  },
  es: {
    translation: es_lang
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "es", 
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;