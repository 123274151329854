import {
  PosnetAPIUrl,
  BillingCUIT,
  BillingAccessToken
} from "../utils/apiUtil";

const serverAPIUrl = localStorage.getItem('server_api_url') ? localStorage.getItem('server_api_url') : PosnetAPIUrl;

const createInvoice = async (type: string, cartTotal: number) => {
  let data: any;
  try {
    const options = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        type: type,
        cartTotal: cartTotal,
        cuit: BillingCUIT,
        accessToken: BillingAccessToken
      })
    };
    const response = await fetch(serverAPIUrl + 'invoice', options);

    if (response && response.status === 200) {
      data = await response.json();
    }
  } catch (e: any) {
    console.log(e);
  }

  return data;
}

export const billingAction = {
  createInvoice,
  get currentAdminTokenValue() { return localStorage.getItem("admin_token") }
};
