import {
  PosnetAPIUrl
} from "../utils/apiUtil";

const serverAPIUrl = localStorage.getItem('server_api_url') ? localStorage.getItem('server_api_url') : PosnetAPIUrl;

const connectDevice = async () => {
  let data: any;

  const config = {
    deviceIp: localStorage.getItem('device_ip'),
  };

  try {
    const options = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      body: JSON.stringify(config)
    };
    const response = await fetch(serverAPIUrl + 'connectDevice', options);
    
    if (response && response.status === 200) {
      data = await response.json();
    }
  } catch (e: any) {
    console.log(e)
  }

  return data;
}

const saveAmount = async (amount: number) => {
  let data: any;
  const config = {
    amount: amount
  };

  try {
    const options = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      body: JSON.stringify(config)
    };
    const response = await fetch(serverAPIUrl + 'saveAmount', options);
    
    if (response && response.status === 200) {
      data = await response.json();
    }
  } catch (e: any) {
    console.log(e)
  }

  return data;
}

const getAmount = async () => {
  let data: any;

  try {
    const options = {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
    };
    const response = await fetch(serverAPIUrl + 'orderAmount', options);
    
    if (response && response.status === 200) {
      data = await response.json();
    }
  } catch (e: any) {
    console.log(e)
  }

  return data;
}

const getPairingCode = async () => {
  let data: any;

  try {
    const options = {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
    };
    const response = await fetch(serverAPIUrl + 'getPairingCode', options);
    
    if (response && response.status === 200) {
      data = await response.json();
    }
  } catch (e: any) {
    console.log(e)
  }

  return data;
}

const checkSaleStatus = async () => {
  let data: any;

  try {
    const options = {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
    };
    const response = await fetch(serverAPIUrl + 'checkSaleStatus', options);
    
    if (response && response.status === 200) {
        data = await response.json();
    }
  } catch (e: any) {
    console.log(e)
  }

  return data;
}

export const deviceAction = {
  connectDevice,
  saveAmount,
  getAmount,
  getPairingCode,
  checkSaleStatus,
  get currentAdminTokenValue() { return localStorage.getItem("admin_token") }
};
