export const es_lang = {
  "common": {
    "main_title": "Don Eduardo P.O.S",
    "title_dashboard": "Panel",
    "title_admins": "Administradores",
    "title_lands": "Tierras",
    "title_users": "Usuarios",
    "title_rooms": "Salas",
    "title_servers": "Servidores",
    "text_action": "Acción",
    "text_modal_delete": "¿Todavía deseas eliminar?",
    "text_selected": "seleccionado(s)",
    "text_search": "Buscar",
    "btn_save": "Guardar",
    "btn_create": "Crear",
    "btn_edit": "Editar",
    "btn_delete": "Eliminar",
    "btn_cancel": "Cancelar",
    "btn_upload": "Subir",
    "btn_login": "Iniciar sesión",
    "btn_connect": "Conectar",
    "btn_deactivate": "Desactivar",
    "btn_activate": "Activar",
    "btn_edit_info": "Editar Información",
    "btn_block": "Bloquear",
    "btn_confirm": "Confirmar",
    "btn_back": "Volver",
    "btn_next": "Continuar",
    "btn_new_order": "Nueva Orden",
    "btn_go_cart": "Ir al Carrito",
    "menu_item_acc_info": "Información de la cuenta",
    "menu_item_logout": "Cerrar sesión",
  },
  "home" : {
    "welcome_text1": "¡Hola!",
    "welcome_text2": "Comencemos."
  },
  "product" : {
    "product_id" : "Código",
    "quantity" : "Cantidad",
    "name" : "Descripción del artículo",
    "price" : "P.Unitario",
    "total" : "P.Total",
  },
  "error": {
    "required_name": "¡Nombre es obligatorio!",
    "required_display_name": "¡El Nombre para mostrar es obligatorio!",
    "required_username": "¡El Nombre de usuario es obligatorio!",
    "required_password": "¡La Contraseña es obligatoria!",
    "required_password_confirm": "¡La Confirmación de la Contraseña es obligatoria!",
    "required_email": "¡El Email es obligatorio!",
    "required_limit_user": "¡El Límite de Usuarios es obligatorio!",
    "required_limit_server": "¡El Límite de Servidores es obligatorio!",
    "required_limit_number": "¡El Número Límite es obligatorio!",
    "required_scale_up": "¡El Escalado hacia arriba es obligatorio!",
    "required_scale_down": "¡El Escalado hacia abajo es obligatorio!",
    "email_format": "¡Por favor, ingresa un formato de email correcto!",
    "password_length": "¡La longitud de la contraseña debe ser al menos de 6 caracteres!",
    "password_same": "Ambas contraseñas deben ser iguales",
    "limit_user_min": "El Límite de Usuarios debe ser mayor a 0",
    "limit_server_min": "El Límite de Servidores debe ser mayor a 0",
    "limit_number_min": "El Número Límite debe ser mayor a 0",
    "scale_up_min": "El Escalado hacia arriba debe ser mayor a 0",
    "scale_down_min": "El Escalado hacia abajo debe ser mayor a 0",
  }
}

