import { FC } from "react";
import { useHistory } from "react-router-dom";
import { Footer } from "../layouts/Footer";
import "../assets/css/step3456.css";

export const Confirm: FC<any> = () => {
  const history = useHistory();

  return (
    <main className="main_content style_list step6">
      <div className="content">
        <div className="box_content box_confirm">
          <div className="box_style box_purchase">
            <div className="title">
              <h3>Confirmar compra</h3>
            </div>
            <button className="add_coupon">Agregar un cupón</button>
            <div className="box_buttons">
              <button className="button_edit_product">
                Agregar/quitar productos
              </button>
              <button className="button_empty_cart">Vaciar carrito</button>
            </div>
          </div>
        </div>
      </div>
      <Footer
        backAction={() => history.push("/step5")}
        nextAction={() => history.push("/step6")}
      />
    </main>
  );
};
