import { FC } from "react";
import { CssBaseline, Grid } from '@material-ui/core';
import { styleCommon } from './styleCommon';
import { useTranslation } from 'react-i18next';
import logo from '../assets/images/Logo.png';
import { Footer } from '../layouts/Footer';

export const Success: FC<any> = () => {
  const classes = styleCommon();
  const [t] = useTranslation();

  setTimeout(() => {
    window.location.href = '/';
  }, 4000);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <Grid container className="main_content">
          <Grid container>
            <Grid item xs={12} className="t_align_center">
              <img src={logo} alt={t("common.main_title")} />
              <p className="welcome_text">
                ¡Orden realizada con éxito! ¡Esperamos verte pronto! Gracias por tu compra.
              </p>
            </Grid>
          </Grid>
        </Grid>
      </main>
      <Footer />
    </div>
  );
};
