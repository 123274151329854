import { FC, useEffect, useRef, useState } from "react";
import { CssBaseline, Grid, TextField } from '@material-ui/core';
import { styleCommon } from './styleCommon';
import { useTranslation } from 'react-i18next';
import logo from '../assets/images/Logo.png';
import iconSearchF8 from '../assets/images/icon_F8.svg';
import { Footer } from '../layouts/Footer';
import { LogoutButton } from "../_components/LogoutButton";
import { ProductList } from "../_components/product/list";
import { orderAction } from "../_actions/order";

export const Cart: FC<any> = () => {
  const currencySymbol = "$";
  const productsStorage = localStorage.getItem('products');
  const cartTotalStorage = localStorage.getItem('cart_total');
  const cartSubTotalStorage = localStorage.getItem('cart_sub_total');
  const cartTotalDiscountStorage = localStorage.getItem('cart_total_discount');
  const couponCodeStorage = localStorage.getItem('coupon_code');

  const classes = styleCommon();
  const [t] = useTranslation();
  const inputCouponRef = useRef(document.createElement('input'));
  const [inputCoupon, setInputCoupon] = useState<string>("");
  const [couponCode, setCouponCode] = useState<string>(couponCodeStorage ? couponCodeStorage : "");
  const [coupon, setCoupon] = useState<any>(null);

  const products = productsStorage ? JSON.parse(productsStorage) : [];  // eslint-disable-line react-hooks/exhaustive-deps
  const originalCartTotal = parseFloat(cartSubTotalStorage ? cartSubTotalStorage : "");
  const [cartTotal, setCartTotal] = useState<number>(parseFloat(cartTotalStorage ? cartTotalStorage : ""));
  const [cartTotalDiscount, setCartTotalDiscount] = useState<number>(parseFloat(cartTotalDiscountStorage ? cartTotalDiscountStorage : '0'));
  const [cartProducts, setCartProducts] = useState(productsStorage ? JSON.parse(productsStorage) : []);

  document.addEventListener('keydown', (event) => {
    if (event.key === 'F8') {
      setInputCoupon("");
      inputCouponRef.current.focus();
    }
  });

  useEffect(() => {
    if(couponCode !== "") {
      getCoupon(couponCode).then((res) => {
        setCoupon(res);
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        setInputCoupon("");
        inputCouponRef.current.focus();
      });
    }
  }, [couponCode]);

  useEffect(() => {
    if(coupon) {
      const reachTotal = parseFloat(coupon.total);
      const couponType = coupon.type;
      const couponDiscount = parseFloat(coupon.discount);
      const couponId = coupon.coupon_id;
      const couponCode = coupon.code;
  
      if(originalCartTotal >= reachTotal) {
        const couponProducts = coupon.products;
        
        if(couponProducts.length > 0) {
          let currentProducts = products;
          let totalDiscount = 0;
          currentProducts = currentProducts.map((product: any) => {
            if(couponProducts.includes(product.id)) {
              product.orgTotal = product.total;
              if(couponType === "F") {
                product.total -= couponDiscount;
                totalDiscount += couponDiscount;
              }
              if(couponType === "P") {
                const pDiscount = product.total * couponDiscount / 100;
                product.total -= pDiscount;
                totalDiscount += pDiscount;
              }

              setCartTotal(originalCartTotal - totalDiscount);
              setCartTotalDiscount(totalDiscount);
    
              localStorage.setItem('coupon_code', couponCode);
              localStorage.setItem('coupon_id', couponId);
            }
            return product;
          });

          setCartProducts(currentProducts);
        } else {
          if(couponType === "F") {
            setCartTotal(originalCartTotal - couponDiscount);
            setCartTotalDiscount(couponDiscount);
  
            localStorage.setItem('coupon_code', couponCode);
            localStorage.setItem('coupon_id', couponId);
          }
      
          if(couponType === "P") {
            const discountP = originalCartTotal * couponDiscount / 100;
            setCartTotal(originalCartTotal - discountP);
            setCartTotalDiscount(discountP);
  
            localStorage.setItem('coupon_code', couponCode);
            localStorage.setItem('coupon_id', couponId);
          }
        }
      }
    } else {
      setCartTotal(originalCartTotal);
      setCartProducts(products);
      localStorage.removeItem('cart_total_discount');
      localStorage.removeItem('coupon_code');
      localStorage.removeItem('coupon_id');
    }
  }, [coupon]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    localStorage.setItem('cart_products', JSON.stringify(cartProducts));
  }, [cartProducts]);

  useEffect(() => {
    localStorage.setItem('cart_total', cartTotal.toFixed(2));
  }, [cartTotal]);

  useEffect(() => {
    localStorage.setItem('cart_total_discount', cartTotalDiscount.toFixed(2));
  }, [cartTotalDiscount]);

  useEffect(() => {
    if (products.length < 1) {
      window.location.href = '/scanner';
    }
  }, [products]);

  const handleCouponKeyDown = async (e: any) => {
    if (e.key === 'Enter') {
      setCouponCode(inputCoupon);
    }
  }

  const getCoupon = async (code: string) => {
    try {
      const couponRes = await orderAction.getVoucher(code);
      if(couponRes) {
        return couponRes;
      } else {
        return null;
      }
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <LogoutButton />
      <main className={classes.content}>
        <Grid container className="main_content">
          <Grid container className="main_header main_header_cart">
            <Grid item xs={4}>
              <img src={logo} alt={t("common.main_title")} />
            </Grid>
            <Grid item xs={4} className="search_box">
              <img src={iconSearchF8} width="57" alt="" />
              <TextField
                autoFocus={true}
                inputRef={inputCouponRef}
                className="txt_search"
                value={inputCoupon}
                variant="outlined"
                placeholder="Cupón"
                onChange={(e) => setInputCoupon(e.target.value)}
                onKeyDown={(e) => handleCouponKeyDown(e)}
              />
              {coupon && (
                <p className="coupon">Cupón Aplicado <span>{coupon.code}</span></p>
              )}
            </Grid>
            <Grid item xs={4} className="header_total">
              <div className="top_price">
                <label>TOTAL</label>
                <div className="box_prices">
                  {coupon && (
                    <div className="box_price_save">
                      <label>Ahorraste</label>
                      <div className="price_save">
                        <p>{currencySymbol}{cartTotalDiscount.toFixed(2)}</p>
                      </div>
                    </div>
                  )}
                  <div className="total_price">
                    <p>{currencySymbol}{cartTotal.toFixed(2)}</p>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container className="products_section has_box_shadow">
            <ProductList products={cartProducts} />
          </Grid>
        </Grid>
      </main>
      <Footer
        backAction={() => {
          window.location.href = '/scanner';
        }}
        nextAction={() => {
          window.location.href = '/payment';
        }}
      />
    </div>
  );
}