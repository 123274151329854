import './App.css';
import './assets/css/main.css';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import axios from 'axios';
import { PrivateRoute } from "./_components/PrivateRoute ";
import { authentication } from './_actions/authentication';
import { Login } from "./pages/login";
import { HomePage } from "./pages/home";
import { Cart } from './pages/cart';
import { Scanner } from './pages/scanner';
import { Payment } from './pages/payment';
import { Tender } from './pages/tender';
import { Billing } from './pages/billing';
import { Confirm } from './pages/confirm';
import { Success } from './pages/success';
import { useTranslation } from 'react-i18next';
import { CssBaseline } from '@material-ui/core';
import { Toaster } from 'react-hot-toast';

axios.interceptors.request.use(function (req) {
  const currentAdminToken = authentication.currentAdminTokenValue;
  req.headers.Authorization = currentAdminToken ? 'Bearer ' +  currentAdminToken : '';

  return req;
}, function (error) {
  console.log(error);
});

axios.interceptors.response.use(
  response => response,
  error => {
    if(error.response.status !== 401) {
      return Promise.reject(error);
    } else {
      // refresh token || logout
      localStorage.removeItem("admin_token");
      window.location.href = "/login";
    }
  }
);

function App() {
  const isAuthenticated = authentication.currentAdminTokenValue ? true : false;
  const [ t ] = useTranslation();
  
  document.title = t("common.main_title");

  return (
    <div className="wrapper">
      <Toaster />
      <CssBaseline/>
      <Router>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <PrivateRoute exact path="/scanner" component={Scanner} isSignedIn={isAuthenticated} />
          <PrivateRoute exact path="/cart" component={Cart} isSignedIn={isAuthenticated} />
          <PrivateRoute exact path="/payment" component={Payment} isSignedIn={isAuthenticated} />
          <PrivateRoute exact path="/tender" component={Tender} isSignedIn={isAuthenticated} />
          <PrivateRoute exact path="/billing" component={Billing} isSignedIn={isAuthenticated} />
          <PrivateRoute exact path="/confirm" component={Confirm} isSignedIn={isAuthenticated} />
          <PrivateRoute exact path="/success" component={Success} isSignedIn={isAuthenticated} />
          <Route exact path="/login" component={Login} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
