import React, { FC } from 'react';
import '../../assets/css/billing.css';
//import logoHead from '../../assets/images/ticket/logo_head.png';
//import logoFoot from '../../assets/images/ticket/logo_foot.png';
import { QRCodeSVG } from 'qrcode.react';

export const BillingTicket: FC<any> = (props) => {
  const {
    products, cartTotal, cashier, order_id,
    employeeName, paymentMethod,
    cuit, billType, billAfip,
    cartSubTotal, cartTotalDiscount, couponCode
  } = props;

  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
  const yyyy = today.getFullYear();
  const hh = String(today.getHours()).padStart(2, '0');
  const min = String(today.getMinutes()).padStart(2, '0');

  const currentDate = `${dd}/${mm}/${yyyy} ${hh}:${min}`;

  let billPaymentMethod = '';
  switch(paymentMethod) {
    case 'posnet':
      billPaymentMethod = 'Otra';
      break;
    case 'mp_standard':
      billPaymentMethod = 'Otra';
      break;
    case 'cash':
      billPaymentMethod = 'Contado';
      break;
    case 'benefit_card':
      billPaymentMethod = 'Tarjeta beneficio';
      break;
    case 'current_account':
      billPaymentMethod = 'Cuenta Corriente';
      break;
    case 'manual':
      billPaymentMethod = 'Otra';
      break;
    default: break;
  }

  return (
    <section className="billing_ticket" style={{ width: '90mm', height: 'auto', margin: '0 auto', textAlign: 'center'}}>
      <div className="logo_container">
      <img src="https://doneduardomarket.com/img/logo_head.png" alt="Don Eduardo" style={{ height: '15vh', maxWidth: '100%' }} />
  </div>
      <h2 style={{ display: 'block', marginLeft: '150px', margin: '0', padding: '0' }}>DON EDUARDO MARKET</h2>
      <h2 style={{ display: 'block', marginLeft: '75px', margin: '0', padding: '0' }}>LA RINCONADA SAS</h2>
      <hr style={{ margin: '10px 0' }} />
      <div className="order_info" style={{ textAlign: 'left', margin: '10px', padding: '0' }}>
        <p style={{ margin: '0', padding: '0' }}>CUIT: {cuit} - IIBB: 000-167236-7</p>
        <p style={{ margin: '0', padding: '0' }}>DOMICILIO COMERCIAL: Av. Ign. de la Rosa 1370 (O) - Capital - San Juan</p>
        <p style={{ margin: '0', padding: '0' }}>INICIO DE ACTIVIDAD: 01/01/2019</p>
        <p style={{ margin: '0', padding: '0' }}>IVA RESPONSABLE INSCRIPTO</p>
      </div>
      <hr style={{ margin: '10px 0' }} />
      <div className="order_info" style={{ textAlign: 'left', margin: '10px', padding: '0' }}>
        <p style={{ margin: '0', padding: '0' }}>FECHA Y HORA {currentDate}</p>
        <p style={{ margin: '0', padding: '0' }}>N° DE COMP: <b>{billAfip.billNumber}</b></p>
        <p style={{ margin: '0', padding: '0' }}>N° DE ORDEN: <b>{order_id}</b></p>
        <p style={{ margin: '0', padding: '0' }}>CAJERO: <b>{employeeName}</b></p>
        <p style={{ margin: '0', padding: '0' }}>CAJA: <b>{cashier}</b></p>
        <p style={{ margin: '0', padding: '0' }}>COND VENTA: <b>{billPaymentMethod}</b></p>
      </div>
      <hr style={{ margin: '10px 0' }} />
      {(paymentMethod === 'benefit_card' || paymentMethod === 'current_account') ? (
        <div className="order_info center" style={{ display: 'block', marginLeft: '150px', margin: '10px', padding: '0' }}>
          <p style={{ margin: '0', padding: '0' }}>RECIBO</p>
          <p style={{ margin: '0', padding: '0' }}>(X)</p>
        </div>
      ) : (
        <div className="order_info center" style={{ display: 'block', marginLeft: '150px', textAlign: 'center', margin: '10px', padding: '0' }}>
          <p style={{ margin: '0', padding: '0', fontWeight: '500' }}>FACTURA {billType}</p>
          <p style={{ margin: '0', padding: '0', fontWeight: '500' }}>(original COD. 006)</p>
        </div>
      )}
      <hr style={{ margin: '10px 0' }} />
      {(paymentMethod === 'benefit_card' || paymentMethod === 'current_account') ? (
        <div className="order_info" style={{ margin: '10px', padding: '0' }}>
          <p style={{ margin: '0', padding: '0' }}>Documento no valido como factura.</p>
        </div>
      ) : (
        <div className="order_info" style={{ margin: '10px', padding: '0' }}>
          <p style={{ margin: '0', padding: '0' }}>Consumidor Final</p>
        </div>
      )}
      <hr style={{ margin: '10px 0' }} />
      <div className="products" style={{ textAlign: 'left', margin: '0', padding: '0' }}>
        <div className="info_head" style={{ fontWeight: '600', borderBottom: '1px solid #e7e7e7', display: 'flex', justifyContent: 'space-between', margin: '0', padding: '0' }}>
          <p className="name" style={{ flex: 2, margin: '0', padding: '0' }}>Descripción (Corta)</p>
          <p className="qty" style={{ flexBasis: '10%', margin: '0', padding: '0' }}>Unid.&nbsp;&nbsp;</p>
          <p className="total" style={{ flexBasis: '10%', margin: '0', padding: '0' }}>&nbsp;&nbsp;Total</p>
        </div>
        {products && products.map((product : any) => {
          return (
            <div className="info" key={product.id} style={{ display: 'flex', justifyContent: 'space-between', fontSize: '0.85rem', margin: '0', padding: '0' }}>
              <p className="name" style={{ flex: 2, margin: '0', padding: '0' }}>{product.name}</p>
              <p className="qty" style={{ flexBasis: '10%', margin: '0', padding: '0' }}>{product.quantity}</p>
              {product.orgTotal && (
                <p className="total" style={{ flexBasis: '10%', margin: '0', padding: '0' }}><s>{product.orgTotal}</s> {product.total.toFixed(2)}</p>
              ) || (
                <p className="total" style={{ flexBasis: '10%', margin: '0', padding: '0' }}>{product.total.toFixed(2)}</p>
              )}
            </div>
          )
        })}
      </div>
      <hr style={{ margin: '10px 0' }} />
      {couponCode ? (
        <React.Fragment>
          <div className="cart_sub_total" style={{ margin: '0', padding: '0' }}>Sub Total: <b>${cartSubTotal.toFixed(2)}</b></div>
          <div className="cart_sub_total" style={{ margin: '0', padding: '0' }}>Coupon ({couponCode}): <b>${cartTotalDiscount.toFixed(2)}</b></div>
          <hr style={{ margin: '10px 0' }} />
          <div className="cart_total" style={{ margin: '0', padding: '0' }}>Total: ${cartTotal.toFixed(2)}</div>
        </React.Fragment>
      ) : (
        <div className="cart_total" style={{ margin: '0', padding: '0' }}>Total: ${cartTotal.toFixed(2)}</div>
      )}
      <hr style={{ margin: '10px 0' }} />
      <div className="afip_info" style={{ margin: '10px', padding: '0' }}>
        <p className="small" style={{ margin: '0', padding: '0' }}>
          {(paymentMethod === 'benefit_card' || paymentMethod === 'current_account')
            ? '***SU FACTURA SE EMITIRA ELECTRONICAMENTE Y ENVIARA POR CORREO A SU EMPRESA***'
            : '***REFERENCIA ELECTRONICA DEL COMPROBANTE***'}
        </p>
        {(paymentMethod === 'benefit_card' || paymentMethod === 'current_account') ? '' : (
          <div>
            <div style={{ display: 'flex', justifyContent: 'center', margin: '0 auto'}}>
              <QRCodeSVG className="bill_qr_code" size={200} value={billAfip.billQRUrl} />
            </div>
            <div className="order_info" style={{ margin: '0', padding: '0' }}>
              <p style={{ margin: '0', padding: '0' }}>CAE: {billAfip.CAE}</p>
              <p style={{ margin: '0', padding: '0' }}>FECHA VTO. CAE: {billAfip.CAEFchVto}</p>
            </div>
          </div>
        )}
      </div>
      <hr style={{ margin: '10px 0' }} />
      <p style={{ margin: '0', padding: '0' }}>Defensa al consumidor San Juan – Tel. 4306408 / 4306408</p>
      <hr style={{ margin: '10px 0' }} />
    </section>
  );
}