import axios from "axios";
import { BaseAPIUrl } from "../utils/apiUtil";

const baseApiUrl = BaseAPIUrl;
const currentAdminToken = localStorage.getItem('admin_token');

interface ProductDTO {
  name: string;
  ean: string;
  price: number;
  quantity: number;
}

const getProduct = async (keyword: string): Promise<any> => {
  let data: any;
  if (currentAdminToken) {
    const params = {
      search: keyword,
      access_token: currentAdminToken
    }

    try {
      const response = await axios.get(baseApiUrl + "product/search", { params });

      if (response) {
        data = response.data;
      }
    } catch (e) {
      console.log((e as any).message);
    }
  }

  return data;
}

const getProductBySku = async (sku: number): Promise<any> => {
  let data: any;
  if (currentAdminToken) {
    const params = {
      search: sku,
      access_token: currentAdminToken
    }

    try {
      const response = await axios.get(baseApiUrl + "product/searchBySku", { params });

      if (response) {
        data = response.data;
      }
    } catch (e) {
      console.log((e as any).message);
    }
  }

  return data;
}

const createProduct = async (productData: ProductDTO): Promise<any> => {
  let data: any;
  if (currentAdminToken) {
    const params = {
      name: productData.name,
      ean: productData.ean,
      price: productData.price,
      quantity: productData.quantity,
      access_token: currentAdminToken
    }

    try {
      const response = await axios.get(baseApiUrl + "product/create", { params });

      if (response) {
        data = response.data;
      }
    } catch (e) {
      console.log((e as any).message);
    }
  }

  return data;
}

const getKeyAuthToEdit = async (key: string) : Promise<any> => {
  let data = null;

  const params = {
    key: key
  }

  try {
    const response = await axios.get(baseApiUrl + "customer/keyAuth", { params });

    if (response) {
      data = response.data;
    }
  } catch (e) {
    console.log((e as any).message);
  }

  return data;
}

export const productAction = {
  getProduct,
  getProductBySku,
  createProduct,
  getKeyAuthToEdit
};