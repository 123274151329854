import { Route, Redirect, RouteProps } from 'react-router-dom';
interface PrivateRouteProps extends RouteProps {
  // tslint:disable-next-line:no-any
  component: any;
  isSignedIn: boolean;
}

export const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, isSignedIn, ...rest } = props;
  if (!isSignedIn) {
    // not logged in so redirect to login page with the return url
    return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
  }

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        <Component {...routeProps} />
      }
    />
  );
};