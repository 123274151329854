import { FC, useEffect } from 'react';
import '../assets/css/footer.css';
import { useLocation } from 'react-router-dom';

export const Footer: FC<any> = (props) => {
  const { backAction, nextAction, nextButtonText = "Ir a Pagar" } = props;
  const location = useLocation();
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = today.getFullYear();

  const currentDate = dd + '/' + mm + '/' + yyyy;

  const nextActionFunc = nextAction ? nextAction : null;
  const backActionFunc = backAction ? backAction : null;

  if (location.pathname === '/scanner') {
    document.addEventListener('keydown', (event) => {
      if (nextActionFunc !== null) {
        if (event.key === 'F4') {
          nextActionFunc();
        }
      }
  
      if (backActionFunc !== null) {
        if (event.key === 'F2') {
          backActionFunc();
        }
      }
    });
  }

  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      if (nextActionFunc !== null) {
        if (event.key === 'F4') {
          nextActionFunc();
        }
      }
  
      if (backActionFunc !== null) {
        if (event.key === 'F2') {
          backActionFunc();
        }
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  return (
    <footer className="footer_section">
      <div className="inner">
        {backAction !== undefined && (
          <div className="btn_footer btn_prev" onClick={backAction}>
            <span>Volver</span>
          </div>
        )}
        <div className="footer_text">
          <p className="footer_date">{currentDate}</p>
          <strong>doneduardomarket</strong>.com
        </div>
        {nextAction !== undefined && (
           <div className="btn_footer btn_next" onClick={nextAction}>
           <span>{nextButtonText}</span>
         </div>
        )}
      </div>
    </footer>
  );
}