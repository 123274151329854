import { FC, useEffect, useRef, useState } from "react";
import { Footer } from '../layouts/Footer';
import { orderAction } from "../_actions/order";
import { deviceAction } from "../_actions/device";
import posnetImg from "../assets/images/posnet.svg";
import mercadopagoImg from "../assets/images/mercadopago.svg";
import tarjetaBeneficioImg from "../assets/images/tarjeta_beneficio.svg";
import numb1Img from "../assets/images/Numb1_3.png";
import numb2Img from "../assets/images/Numb2_5.png";
import numb3Img from "../assets/images/Numb3_5.png";
import numb4Img from "../assets/images/Numb4_5.png";
import numb5Img from "../assets/images/ICONO_5.svg";
import numb6Img from "../assets/images/ICONO_6.svg";
import "../assets/css/step3456.css";
import { LogoutButton } from "../_components/LogoutButton";
import { Modal, TextField } from "@material-ui/core";

export const Payment: FC<any> = () => {
  const inputSerialNumberRef = useRef(document.createElement('input'));
  const inputReceiptNumberRef = useRef(document.createElement('input'));

  const [paymentMethod, setPaymentMethod] = useState<string>(""); 
  const [openCardForm, setOpenCardForm] = useState<boolean>(false);
  const [openCardMessageNotice, setOpenCardMessageNotice] = useState<boolean>(false);
  const [openCardMoneyNotice, setOpenCardMoneyNotice] = useState<boolean>(false);
  const [openReceiptForm, setOpenReceiptForm] = useState<boolean>(false);
    // Estados para manejar el modal de efectivo y los montos
  const [openCashModal, setOpenCashModal] = useState<boolean>(false);
  const [cashAmount, setCashAmount] = useState<string>("");
  const [changeAmount, setChangeAmount] = useState<number>(0);

  const [serialNumber, setSerialNumber] = useState<string>("");
  const [securityCode, setSecurityCode] = useState<string>("");
  const [isCardChecked, setIsCardChecked] = useState<boolean>(false);
  const [receiptNumber, setReceiptNumber] = useState<string>("");

  const productsStorage = localStorage.getItem('products');
  const cartTotalStorage = localStorage.getItem('cart_total');
  const couponCodeStorage = localStorage.getItem('coupon_code');

  const products = productsStorage ? JSON.parse(productsStorage) : [];
  const cartTotal = parseFloat(cartTotalStorage ? cartTotalStorage : "");

  document.addEventListener('keydown', (event) => {
    if (event.shiftKey && event.code === 'Digit1') {
      setPaymentMethod('');
    }
    if (event.shiftKey && event.code === 'Digit2') {
      setPaymentMethod('mp_standard');
    }
    if (event.shiftKey && event.code === 'Digit3') {
      setPaymentMethod('cash');
    }
    if (event.shiftKey && event.code === 'Digit4') {
      setPaymentMethod('benefit_card');
    }
    if (event.shiftKey && event.code === 'Digit5') {
      setPaymentMethod('current_account');
    }
    if (event.shiftKey && event.code === 'Digit6') {
      setPaymentMethod('manual');
    }
    if (event.shiftKey && event.code === 'Delete') {
      setOpenCardForm(false);
      setOpenCardMessageNotice(false);
      setOpenReceiptForm(false);
    }
  });

  useEffect(() => {
    localStorage.setItem('payment_method', paymentMethod);
    console.log(paymentMethod);
  }, [paymentMethod]);

  useEffect(() => {
    if (products.length < 1) {
      window.location.href = '/scanner';
    }
  }, [products]);

  const handlePaymentProcess = async (): Promise<void> => {
    const localPaymentMethod = localStorage.getItem('payment_method') || "";
    if (localPaymentMethod === "") {
      return;
    }
  
    // Lógica para POSNET
    if (localPaymentMethod === 'posnet') {
      await deviceAction.saveAmount(cartTotal);
      window.location.href = '/tender';
      return;
    }
  
    // Lógica para Mercado Pago
    if (localPaymentMethod === 'mp_standard') {
      try {
        const resOrderId = await orderAction.createOrderForDB(products, cartTotal, localPaymentMethod);
        if (resOrderId) {
          const resCheckout = await orderAction.checkoutMercadoPayment(resOrderId, products, cartTotal);
          if (resCheckout && resCheckout.status === 200) {
            const intervalOrderStatus = setInterval(async () => {
              const resMpOrderStatus = await orderAction.getMercadoOrderStatus(resOrderId);
              if (resMpOrderStatus.status === 200 && resMpOrderStatus.data.elements !== null) {
                clearInterval(intervalOrderStatus);
                const mpOrderStatus = resMpOrderStatus.data.elements[0].order_status;
                if (mpOrderStatus === "paid") {
                  localStorage.setItem('order_id', resOrderId.toString());
                  window.location.href = '/billing';
                }
              } else {
                clearInterval(intervalOrderStatus);
                await orderAction.deleteOrderForDB(resOrderId);
              }
            }, 2000);
          } else {
            await orderAction.deleteOrderForDB(resOrderId);
          }
        }
      } catch (e) {
        console.log((e as any).message);
      }
      return;
    }
  
    // Lógica para Cuenta Corriente o Tarjeta Beneficio
    if (localPaymentMethod === 'current_account' || localPaymentMethod === 'benefit_card') {
      setOpenCardForm(true);
      return;
    }
  
    // Lógica para Pago Manual
    if (localPaymentMethod === 'manual') {
      setOpenReceiptForm(true);
      return;
    }
  
    // Lógica para Pago en Efectivo
    if (localPaymentMethod === 'cash') {
      try {
        setOpenCashModal(true); // Abre el modal para ingresar el monto de efectivo
        return;  // Espera a que el usuario ingrese el monto antes de proceder
      } catch (e) {
        console.log((e as any).message);
      }
    }
  
    // Lógica para finalizar la orden después de ingresar el monto de efectivo (en el modal)
    try {
      const resOrderId = await orderAction.createOrderForDB(products, cartTotal, localPaymentMethod);
      if (resOrderId) {
        setIsCardChecked(true);  // Este estado puede ser útil si chequeas algo más adelante
        setOpenCardMessageNotice(true);  // Notificación para mostrar que la orden está siendo procesada
  
        setTimeout(() => {
          setOpenCardMessageNotice(false);  // Cierra la notificación
          localStorage.setItem('order_id', resOrderId);  // Guarda el ID de la orden
          window.location.href = '/billing';  // Redirecciona al módulo de facturación
        }, 3000);
      }
    } catch (e) {
      console.log((e as any).message);
    }
  };
  const handleCashSubmit = async (e: any) => {
    if (e.key === 'Enter') { // Solo proceder si el usuario presiona "Enter"
      const cash = parseFloat(cashAmount);  // Convertimos el monto ingresado a número
      
      if (isNaN(cash)) {
        alert("Por favor, ingrese un monto válido.");
        return;
      }
  
      // Verificamos si el monto es suficiente para cubrir el total
      if (cash >= cartTotal) {
        const change = cash - cartTotal;  // Calculamos el vuelto
        setChangeAmount(change);  // Guardamos el vuelto
  
        // Mostramos el vuelto por 2 segundos y luego procesamos el pago
        setTimeout(async () => {
          try {
            const resOrderId = await orderAction.createOrderForDB(products, cartTotal, 'cash', '', '', '', '');
            
            // Guardamos los detalles de la transacción en efectivo
            await orderAction.saveCashTransaction(resOrderId, cash, change);  // Guardamos en histórico
    
            setOpenCashModal(false);  // Cerramos el modal
            localStorage.setItem('order_id', resOrderId);  // Guardamos el ID de la orden
            window.location.href = '/billing';  // Redireccionamos a la página de facturación
          } catch (error) {
            console.error("Error al procesar el pago en efectivo:", error);
          }
        }, 10000);  // Procesamos después de 2 segundos
      } else {
        alert("El monto ingresado es insuficiente.");
      }
    }
  }
///////
  const handleCheckCard = async (e: any) => {
    if (e.key === 'Enter') {
      try {
        const data = await orderAction.getCustomerFromCard(serialNumber, securityCode);

        if (data && data.customer_id && data.isCard === 1) {
          let statusCheck = false;
          setOpenCardForm(false);

          if (data.amount >= cartTotal) {
            if (paymentMethod === "benefit_card" && data.isCurrentAccount === 0) statusCheck = true;
            if (paymentMethod === "current_account" && data.isCurrentAccount === 1) statusCheck = true;
          } else {
            setOpenCardMoneyNotice(true);
          }

          if (statusCheck) {
            setIsCardChecked(true);
            setOpenCardMessageNotice(true);

            const resOrderId = await orderAction.createOrderForDB(
              products, cartTotal, paymentMethod, data.customer_id, receiptNumber, serialNumber, securityCode
            );

            setSerialNumber('');
            setSecurityCode('');
            localStorage.setItem('order_id', resOrderId);
            window.location.href = '/billing';
          } else {
            setOpenCardMessageNotice(true);
            setTimeout(() => {
              setOpenCardMessageNotice(false);
              setOpenCardForm(true);
              inputSerialNumberRef.current.focus();
            }, 3000);
          }
        } else {
          throw new Error('Tarjeta no válida');
        }
      } catch (error) {
        console.error('Error al verificar la tarjeta:', error);
        setOpenCardForm(false);
        setOpenCardMessageNotice(true);

        setTimeout(() => {
          setOpenCardMessageNotice(false);
          setOpenCardForm(true);
          inputSerialNumberRef.current.focus();
        }, 3000);
      }
    }
  }

  const handleSetReceiptNumber = async (e: any) => {
    if (e.key === 'Enter' && receiptNumber !== "") {
      try {
        const resOrderId = await orderAction.createOrderForDB(products, cartTotal, paymentMethod, '', receiptNumber);

        setIsCardChecked(true);
        setOpenReceiptForm(false);
        setOpenCardMessageNotice(true);

        setTimeout(() => {
          setOpenCardMessageNotice(false);
          localStorage.setItem('order_id', resOrderId);
          window.location.href = '/billing';
        }, 3000);
      } catch (error) {
        console.error('Error al procesar el recibo:', error);
      }
    }
  }

  return (
    <main className="main_content style_list step3">
      <LogoutButton />
      <div className="content">
        <div className="box_content">
          <div className="box_style box_payment_method">
            <div className="title">
              <h3>Elegí el Método de pago</h3>
              <div className="box_total">
                <p className="total_label">Total</p>
                <p className="total_value">${cartTotal.toFixed(2)}</p>
                {couponCodeStorage && (
                  <p>- Cupón: {couponCodeStorage}</p>
                )}
              </div>
            </div>
            <ul className="list_payment_method">
              <li className={`${paymentMethod === "" ? "active" : ""} disabled`}>
                <div className="text">
                  <img src={numb1Img} alt="" />
                  <h5>Posnet</h5>
                </div>
                <div className="image">
                  <img width="120" src={posnetImg} alt="Posnet" />
                </div>
              </li>
              <li className={paymentMethod === "mp_standard" ? "active" : ""} onClick={() => setPaymentMethod("mp_standard")}>
                <div className="text">
                  <img src={numb2Img} alt="" />
                  <h5>Mercadopago</h5>
                </div>
                <div className="image">
                  <img width="120" src={mercadopagoImg} alt="Mercadopago" />
                </div>
              </li>
              <li className={paymentMethod === "cash" ? "active" : ""} onClick={() => setPaymentMethod("cash")}>
                <div className="text">
                  <img src={numb3Img} alt="" />
                  <h5>Efectivo</h5>
                </div>
              </li>
              <li className={paymentMethod === "benefit_card" ? "active" : ""} onClick={() => setPaymentMethod("benefit_card")}>
                <div className="text">
                  <img src={numb4Img} alt="" />
                  <h5>Tarjeta beneficio</h5>
                </div>
                <div className="image">
                  <img width="120" src={tarjetaBeneficioImg} alt="Tarjeta beneficio" />
                </div>
              </li>
              <li className={paymentMethod === "current_account" ? "active" : ""} onClick={() => setPaymentMethod("current_account")}>
                <div className="text">
                  <img height={44} src={numb5Img} alt="" />
                  <h5>Cuenta Corriente</h5>
                </div>
              </li>
              <li className={paymentMethod === "manual" ? "active" : ""} onClick={() => setPaymentMethod("manual")}>
                <div className="text">
                  <img height={44} src={numb6Img} alt="" />
                  <h5>Pago Manual</h5>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <Modal
          className="tender_modal modal_receipt"
          open={openReceiptForm}
        >
          <div className="popup_content">
            <h4>Por favor, ingrese el número de recibo.</h4>
            <TextField
              className="txt_card txt_receipt_number"
              autoFocus={true}
              inputRef={inputReceiptNumberRef}
              value={receiptNumber}
              fullWidth
              variant="outlined"
              placeholder="Comprobante"
              onChange={(e) => setReceiptNumber(e.target.value)}
              onKeyDown={(e) => handleSetReceiptNumber(e)}
            />
          </div>
        </Modal>
        <Modal
          className="tender_modal modal_card"
          open={openCardForm}
        >
          <div className="popup_content">
            <h4>Complete la información de la tarjeta.</h4>
            <TextField
              className="txt_card txt_serial_number"
              autoFocus={true}
              inputRef={inputSerialNumberRef}
              value={serialNumber}
              fullWidth
              variant="outlined"
              placeholder="Serial Number"
              onChange={(e) => setSerialNumber(e.target.value)}
              onKeyDown={(e) => handleCheckCard(e)}
            />
            <TextField
              className="txt_card txt_security_code"
              value={securityCode}
              fullWidth
              variant="outlined"
              placeholder="Security Code"
              onChange={(e) => setSecurityCode(e.target.value)}
              onKeyDown={(e) => handleCheckCard(e)}
            />
          </div>
        </Modal>
        <Modal
          className="tender_modal modal_card_message"
          open={openCardMessageNotice}
        >
          <div className={(isCardChecked ? "success" : "failed") + " popup_content"}>
            {isCardChecked ? (
              <h4>¡Verificada exitosamente y orden creada!</h4>
            ) : (
              <h4>
                {openCardMoneyNotice ? "La tarjeta no tiene suficiente saldo." : "La información de la tarjeta es inválida."}
              </h4>
            )}
          </div>
        </Modal>
        <Modal
  className="tender_modal modal_cash"
  open={openCashModal}
  onClose={() => setOpenCashModal(false)}
>
  <div className="popup_content">
    <h4>Monto de la compra: ${cartTotal.toFixed(2)}</h4>
    
    {/* Campo para ingresar el monto en efectivo */}
    <TextField
      className="txt_cash_amount"
      autoFocus={true}
      value={cashAmount}
      fullWidth
      variant="outlined"
      placeholder="Ingrese el monto en efectivo"
      onChange={(e) => setCashAmount(e.target.value)}  // Guardamos el valor ingresado
      onKeyDown={handleCashSubmit}  // Procesamos al presionar Enter
      InputProps={{
        style: { backgroundColor: "white" }, // Aseguramos que el fondo sea blanco
      }}
    />
    
    {/* Mostrar el vuelto, si ya fue calculado */}
    {changeAmount > 0 && <h4>Vuelto a dar: ${changeAmount.toFixed(2)}</h4>}
  </div>
</Modal>
      </div>
      <Footer
        backAction={() => {
          window.location.href = '/cart';
        }}
        nextAction={() => handlePaymentProcess()}
      />
    </main>
  );
}
