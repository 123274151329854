import axios from "axios";
import {
  BaseAPIUrl, 
  DevicesAPICashier
} from "../utils/apiUtil";

const baseApiUrl = BaseAPIUrl;

const connectToSystem = (): void => {
  window.location.href = "/login";
}

const setBaseApiUrl = async (cashierDevice: string): Promise<string | undefined> => {
  console.log('setBaseApiUrl llamado con cashierDevice:', cashierDevice);
  const deviceData = DevicesAPICashier.find(item => item.device === cashierDevice);
  if (deviceData) {
    localStorage.setItem('device_ip', deviceData.deviceIp);
    localStorage.setItem('mp_pos_id', deviceData.mpPosId);

    try {
      const response = await axios.get(deviceData.api + "checkApi");
      if (response.status === 200) {
        localStorage.setItem('server_api_url', deviceData.api);
        return 'success';
      }
    } catch (e) {
      localStorage.setItem('server_api_url', deviceData.subApi);
    }
  }
}

const getAuthAPIPin = async (pin: string): Promise<string> => {
  console.log('getAuthAPIPin llamado con pin:', pin);
  try {
    const response = await axios.post(`${baseApiUrl}auth/pin`, { pin });
    console.log('Respuesta de auth/pin:', response);

    if (response.status === 200) {
      const user = response.data.user;
      localStorage.setItem("admin_token", pin);
      localStorage.setItem("employee_id", user.id);
      localStorage.setItem("employee_name", user.username);
      window.location.href = "/";
      return 'success';
    } else {
      console.error('PIN no encontrado');
      return 'failure';
    }
  } catch (e) {
    if (axios.isAxiosError(e)) {
      console.error('Error de Axios:', e.message);
    } else {
      console.error('Ocurrió un error inesperado:', e);
    }
    return 'failure';
  }
}

const useAccessToken = async (userId: number): Promise<void> => {
  console.log('useAccessToken llamado con userId:', userId);
  try {
    const response = await axios.get(`${baseApiUrl}users/${userId}`);
    console.log('Respuesta de users/:userId:', response);

    if (response.status === 200) {
      const user = response.data;
      localStorage.setItem("admin_token", user.pin);
      localStorage.setItem("employee_id", user.id);
      localStorage.setItem("employee_name", user.username);
      window.location.href = "/";
    } else {
      connectToSystem();
    }
  } catch (e) {
    if (axios.isAxiosError(e)) {
      console.error('Error de Axios:', e.message);
    } else {
      console.error('Ocurrió un error inesperado:', e);
    }
    connectToSystem();
  }
}

const logout = (): void => {
  localStorage.removeItem("admin_token");
  localStorage.removeItem("employee_id");
  localStorage.removeItem("employee_name");
  // Otros elementos de localStorage que necesites limpiar.
  localStorage.removeItem("employee_code");
  localStorage.removeItem("products");
  localStorage.removeItem("cart_total");
  localStorage.removeItem("cart_sub_total");
  localStorage.removeItem("device_ip");
  localStorage.removeItem("mp_pos_id");
  localStorage.removeItem("cashier");
  localStorage.removeItem("server_api_url");
  localStorage.removeItem("ip");
  localStorage.removeItem("order_id");
  localStorage.removeItem("payment_method");
  localStorage.removeItem("coupon_code");
  localStorage.removeItem("coupon_id");
  localStorage.removeItem("cart_total_discount");
  localStorage.removeItem("products_new_created");
  localStorage.removeItem("products_custom_price");
  localStorage.removeItem("auth_key");
  localStorage.removeItem("orders");
}

const getEmployees = async (): Promise<any[]> => {
  // Este método puede ser removido si ya no es necesario obtener empleados por rol
  return [];
}

export const authentication = {
  connectToSystem,
  setBaseApiUrl,
  getAuthAPIPin,
  logout,
  useAccessToken,
  getEmployees,
  get currentAdminTokenValue() { return localStorage.getItem("admin_token") }
};
