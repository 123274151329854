import React, { FC, useEffect, useState } from "react";
import { Footer } from '../layouts/Footer';
import { billingAction } from "../_actions/billing";
import { reportAction } from "../_actions/report";
import "../assets/css/step3456.css";
import { LogoutButton } from "../_components/LogoutButton";
import { BillingTicket } from "../_components/billing/ticket";
import { BillingCUIT } from '../utils/apiUtil';

export const Billing: FC<any> = () => {
  const [billType, setBillType] = useState<string>("B");
  const [billAfip, setBillAfip] = useState<any>({});

  const productsStorage = localStorage.getItem('products');
  const cartProductsStorage = localStorage.getItem('cart_products');
  const productsCustomPriceStorage = localStorage.getItem('products_custom_price');
  const productsNewCreatedStorage = localStorage.getItem('products_new_created');
  const authKeyStorage = localStorage.getItem('auth_key');
  const cartTotalStorage = localStorage.getItem('cart_total');
  const employeeName = localStorage.getItem('employee_name');
  const paymentMethod = localStorage.getItem('payment_method');
  const cashier = localStorage.getItem('cashier');
  const order_id = localStorage.getItem('order_id');
  const cartSubTotalStorage = localStorage.getItem('cart_sub_total');
  const cartTotalDiscountStorage = localStorage.getItem('cart_total_discount');
  const couponCodeStorage = localStorage.getItem('coupon_code');

  const products = productsStorage ? JSON.parse(productsStorage) : [];
  const cartProducts = cartProductsStorage ? JSON.parse(cartProductsStorage) : [];
  const productsCustomPrice = productsCustomPriceStorage ? productsCustomPriceStorage : "";
  const productsNewCreated = productsNewCreatedStorage ? productsNewCreatedStorage : "";
  const authKey = authKeyStorage ? authKeyStorage : "";
  const cartTotal = parseFloat(cartTotalStorage ? cartTotalStorage : "");
  const cartSubTotal = parseFloat(cartSubTotalStorage ? cartSubTotalStorage : "");
  const cartTotalDiscount = parseFloat(cartTotalDiscountStorage ? cartTotalDiscountStorage : "");

  useEffect(() => {
    if (products.length < 1) {
      window.location.href = '/scanner';
    } else {
      handleBillingProcess();
    }
  }, []);

  const handleBillingProcess = async () => {
    if(billType === "B") {
      await saveProductsCustomPriceReport();
      await saveProductsNewCreatedReport();
  
      if(paymentMethod === 'benefit_card' || paymentMethod === 'current_account') {
        // Do nothing
      } else {
        const response = await billingAction.createInvoice(billType, cartSubTotal);
        if (response) {
          setBillAfip(
            {
              CAE: response.CAE,
              CAEFchVto: response.CAEFchVto,
              billNumber: response.billNumber,
              billQRUrl: response.billQRUrl
            }
          );
        }
      }
  
      // Abrir la ventana emergente antes de generar el contenido
      const printWindow = window.open('', '_blank');
      if (printWindow) {
        const svgElement = document.getElementById('pdfTicket') as HTMLElement;
        if (svgElement) {
          const svgXml = new XMLSerializer().serializeToString(svgElement);
          printWindow.document.write(svgXml);
          printWindow.document.close();
          printWindow.print();
          printWindow.close();
        }
      }
  
      window.location.href = '/success';
    }
  }
  
  const saveProductsCustomPriceReport = async () => {
    if(authKey !== '' && productsCustomPrice !== "") {
      const reportRes = await reportAction.saveCustomPriceReport(productsCustomPrice, authKey, order_id);
      if(reportRes) {
        localStorage.removeItem('products_custom_price');
      }
    }
  }

  const saveProductsNewCreatedReport = async () => {
    if(authKey !== '' && productsNewCreated !== "") {
      const reportRes = await reportAction.saveNewCreatedReport(productsNewCreated, authKey, order_id);
      if(reportRes) {
        localStorage.removeItem('products_new_created');
      }
    }
  }

  return (
    <main className="main_content style_list step5 ">
      <LogoutButton />
      <div className="content">
        <div className="box_content">
          <div className="box_style">
            <div className="title billing_title">
              <h3>Facturación</h3>
              <div className="box_total">
                <p className="total_label">Total</p>
                <p className="total_value">${cartTotal.toFixed(2)}</p>
                {couponCodeStorage && (
                  <p>- Coupon: {couponCodeStorage}</p>
                )}
              </div>
            </div>
            <ul>
              <li
                className={billType === "B" ? "active" : ""}
                key={billType}
                onClick={() => setBillType("B")}
              >
                <div className="text">
                  <h5>Ticket común</h5>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer
        nextAction={() => handleBillingProcess()}
        nextButtonText="Imprimir Ticket"
      />
      <div id="pdfTicket">
        <BillingTicket
          products={cartProducts}
          cartTotal={cartTotal}
          cartSubTotal={cartSubTotal}
          cartTotalDiscount={cartTotalDiscount}
          couponCode={couponCodeStorage}
          employeeName={employeeName}
          paymentMethod={paymentMethod}
          cuit={BillingCUIT}
          billType={billType}
          billAfip={billAfip}
          cashier={cashier}
          order_id={order_id}
          generateSVG={true}
        />
      </div>
    </main>
  );
}
