import axios from "axios";
import { BaseAPIUrl } from "../utils/apiUtil";

const baseApiUrl = BaseAPIUrl;
const employeeNameStorage = localStorage.getItem('employee_name');

const saveCustomPriceReport = async (productsData: string, authKey: string, orderId: any) => {
  let data: any;

  let params = {
    products: productsData,
    auth_key: authKey,
    employee: employeeNameStorage,
    order_id: orderId
  };

  const config = {
    headers: {
      'content-type': 'application/json'
    },
    params: params,
  }

  try {
    const response = await axios.post(baseApiUrl + "report/customPrice", config);

    if (response && response.status === 201) {
      data = response.data;
    }
  } catch (e) {
    console.log((e as any).message);
  }

  return data;
}

const saveNewCreatedReport = async (productsData: string, authKey: string, orderId: any) => {
  let data: any;

  let params = {
    products: productsData,
    auth_key: authKey,
    employee: employeeNameStorage,
    order_id: orderId
  };

  const config = {
    headers: {
      'content-type': 'application/json'
    },
    params: params,
  }

  try {
    const response = await axios.post(baseApiUrl + "report/newProduct", config);

    if (response && response.status === 201) {
      data = response.data;
    }
  } catch (e) {
    console.log((e as any).message);
  }

  return data;
}

export const reportAction = {
  saveCustomPriceReport,
  saveNewCreatedReport
};