import { FC, useState } from "react";
import { Container, Grid, makeStyles, Typography, TextField, Button } from '@material-ui/core';
import { authentication } from '../../_actions/authentication';
import { useTranslation } from "react-i18next";
import logo from "../../assets/images/Logo.png";
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    height: "100%",
    display: 'flex',
    alignItems: 'center',
  },
  error: {
    color: 'red',
  },
  heading: {
    color: '#FFF',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  gridContent: {
    justifyContent: 'center',
  },
  connectBtn: {
    backgroundColor: '#000',
    borderColor: '#000',
    fontWeight: 'bold',
    "&:hover": {
      backgroundColor: '#FFF',
      borderColor: '#ff4341',
      color: '#000'
    }
  }
}));

export const Login: FC<any> = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [pinInput, setPinInput] = useState<string>("");
  const [loginFailed, setLoginFailed] = useState<boolean>(false);
  const [loginSuccess, setLoginSuccess] = useState<boolean>(false);

  const handleConnect = async (e: any) => {
    if (e.key === 'Enter' || e.type === 'click') {
      setLoginFailed(false);
      setLoginSuccess(false);
      if (pinInput !== "") {
        const res = await authentication.getAuthAPIPin(pinInput);
        if (res === 'success') {
          setLoginSuccess(true);
          localStorage.setItem("employee_code", pinInput);
        } else {
          setLoginFailed(true);
        }
      } else {
        setLoginFailed(true);
      }
    }
  }

  return (
    <Container className={classes.container} maxWidth="xs">
      <Grid className={classes.gridContent} container spacing={3}>
        <Typography className={classes.heading} variant="h4" component="h1">
          {t("common.main_title")}
        </Typography>
        <img className="login_logo" src={logo} alt={t("common.main_title")} />
        <Grid item xs={12}>
          <TextField
            className="txt_pin"
            autoFocus={true}
            value={pinInput}
            fullWidth
            variant="outlined"
            onChange={(e) => setPinInput(e.target.value)}
            onKeyDown={(e) => handleConnect(e)}
            placeholder="Ingrese su PIN"
          />
        </Grid>
        {loginFailed && (
          <Alert className="alert_notify" variant="filled" severity="error" color="warning">
            ¡Inicio de sesión fallido!
          </Alert>
        )}
        {loginSuccess && (
          <Alert className="alert_notify" variant="filled" severity="success">
            ¡Inicio de sesión exitoso!
          </Alert>
        )}
      </Grid>
    </Container>
  );
};

export default Login;
