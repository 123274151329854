import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Footer } from '../layouts/Footer';
import { orderAction } from "../_actions/order";
import { deviceAction } from "../_actions/device";
import "../assets/css/step3456.css";
import numb1Img from "../assets/images/Numb1_3.png";
import numb2Img from "../assets/images/Numb2_5.png";
import numb3Img from "../assets/images/Numb3_5.png";
import numb4Img from "../assets/images/Numb4_5.png";
import { LogoutButton } from "../_components/LogoutButton";
import { Modal } from "@material-ui/core";

const numbIcons = [
  numb1Img,
  numb3Img,
  numb2Img,
  numb4Img,
]

export const Tender: FC<any> = () => {
  const location = useLocation();
  const [tenders, setTenders] = useState<any>([]);
  const [tenderId, setTenderId] = useState<string>("");
  const [pairingCode, setPairingCode] = useState<string>('');
  const [saleStatus, setSaleStatus] = useState<string>('');
  const [showPairingCode, setShowPairingCode] = useState<boolean>(false);

  const productsStorage = localStorage.getItem('products');
  const cartTotalStorage = localStorage.getItem('cart_total');
  // const cartSubTotalStorage = localStorage.getItem('cart_sub_total');
  // const cartTotalDiscountStorage = localStorage.getItem('cart_total_discount');
  const couponCodeStorage = localStorage.getItem('coupon_code');

  const products = productsStorage ? JSON.parse(productsStorage) : [];  // eslint-disable-line react-hooks/exhaustive-deps
  const cartTotal = parseFloat(cartTotalStorage ? cartTotalStorage : "");

  const pathName = location.pathname;

  useEffect(() => {
    getTenders();
  }, []);

  useEffect(() => {
    if (tenders.length > 0) {
      setTenderId(tenders[0].id);
    }
  }, [tenders]);

  useEffect(() => {
    console.log(tenderId);
  }, [tenderId]);

  useEffect(() => {
    if (pairingCode !== "") {
      setShowPairingCode(true);
    } else {
      setShowPairingCode(false);
    }
  }, [pairingCode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const createDBOrder = async () => {
      try {
        const resOrderId = await orderAction.createOrderForDB(products, cartTotal, 'posnet');
        localStorage.setItem('order_id', resOrderId);
      } catch (e) {
        console.log((e as any).message);
      }
    }

    if (pathName === '/tender') {
      const saleInterval = setInterval(async () => {
        const saleStatusRes = await deviceAction.checkSaleStatus();

        if(saleStatusRes) {
          setSaleStatus(saleStatusRes);
        } else {
          clearInterval(saleInterval);
        }
      }, 4000);

      if (saleStatus === "success") {
        clearInterval(saleInterval);
        setPairingCode('');
        setSaleStatus('');
        createDBOrder().catch(console.error);
        window.location.href = '/billing';
      }

      if (saleStatus === "failed") {
        clearInterval(saleInterval);
        setPairingCode('');
        setSaleStatus('');
      }

      if (saleStatus === "close") {
        clearInterval(saleInterval);
        setPairingCode('');
        setSaleStatus('');
        rePaymentProcess();
      }
    }
  }, [saleStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (products.length < 1) {
      window.location.href = '/scanner';
    }
  }, [products]); // eslint-disable-line react-hooks/exhaustive-deps

  const getTenders = async () => {
    const tenders = await orderAction.getCloverTenders();
    setTenders(tenders);
  }

  const handleSetPaymentMethod = (tender: any) => {
    setTenderId(tender.id);
  }

  const handlePaymentProcess = async () => {
    /**
     * Connect Device Posnet
     */
    const deviceConnection = await deviceAction.connectDevice();
    if (deviceConnection) {
      const pairingInterval = setInterval(async () => {
        const pairingCode = await deviceAction.getPairingCode();

        if (pairingCode && pairingCode !== "") {
          setPairingCode(pairingCode);
          clearInterval(pairingInterval);
        }
      }, 1000);
    }
    /* End */
  }

  const rePaymentProcess = async () => {
    const deviceConnection = await deviceAction.connectDevice();
    console.log(deviceConnection);
  }

  return (
    <main className="main_content style_list step3">
      <LogoutButton />
      <div className="content">
        <div className="box_content">
          <div className="box_style box_payment_method">
            <div className="title">
              <h3>Choose tender for Posnet payment</h3>
              <div className="box_total">
                <p className="total_label">Total</p>
                <p className="total_value">${cartTotal.toFixed(2)}</p>
                {couponCodeStorage && (
                  <p>- Coupon: {couponCodeStorage}</p>
                )}
              </div>
            </div>
            <ul className="list_payment_method">
              {tenders && tenders.map((item: any) => {
                return (
                  <li
                    className={tenderId === item.id ? "active" : ""}
                    onClick={() => handleSetPaymentMethod(item)}
                    key={item.id}
                  >
                    <div className="text">
                      <img src={numbIcons[item.index]} alt="" />
                      <h5>{item.label}</h5>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
        <Modal
          className="tender_modal modal_pairing"
          open={showPairingCode}
        >
          <div className="popup_content">
            <h4>The Pairing Code to Device</h4>
            <p className="code-text">{pairingCode}</p>
          </div>
        </Modal>
      </div>
      <Footer
        backAction={() => {
          window.location.href = '/payment';
        }}
        nextAction={() => handlePaymentProcess()}
      />
    </main>
  );
}