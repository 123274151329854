import { FC, useState, useEffect, useRef } from "react";
import { CssBaseline, Grid, TextField, Modal, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { styleCommon } from "./styleCommon";
import logo from '../assets/images/Logo.png';
import iconSearchF8 from '../assets/images/icon_F8.svg';
import barcode_sample from '../assets/images/barcode_sample.png';
import beepSound from '../assets/sound/beep.mp3';
import pumSound from '../assets/sound/pum.mp3';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { Footer } from "../layouts/Footer";
import { LogoutButton } from "../_components/LogoutButton";
import { ProductList } from "../_components/product/list";
import { useHistory } from "react-router-dom";
import { productAction } from "../_actions/product";

interface ProductData {
  key: string;
  id: number;
  quantity: number;
  name: string;
  price: number | any;
  total: number;
}

interface ProductCustomPrice {
  id: number,
  price: number,
  quantity: number
}

export const Scanner: FC<any> = () => {
  const classes = styleCommon();
  const [t] = useTranslation();
  const history = useHistory();
  const inputQtyRef = useRef(document.createElement('input'));
  const inputEanRef = useRef(document.createElement('input'));
  const inputSearchRef = useRef(document.createElement('input'));

  const [inputEan, setInputEan] = useState<string>("");
  const [inputQty, setInputQty] = useState<string>("");
  const [inputSearch, setInputSearch] = useState<string>("");

  const [showQtyAction, setShowQtyAction] = useState<boolean>(false);
  const [addCartAction, setAddCartAction] = useState<boolean>(false);
  const [gotoCartStatus, setGotoCartStatus] = useState<boolean>(false);
  const [isMinusProduct, setIsMinusProduct] = useState<boolean>(false);
  const [product, setProduct] = useState<any>(null);
  const [products, setProducts] = useState<ProductData[]>([]);
  const [cartSubTotal, setCartSubTotal] = useState<number>(0);

  const [showCreateProductAction, setShowCreateProductAction] = useState<boolean>(false);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [frmProductName, setFrmProductName] = useState<string>("");
  const [frmProductPrice, setFrmProductPrice] = useState<string>("");
  const [frmProductQuantity, setFrmProductQuantity] = useState<string>("");
  const [frmProductEan, setFrmProductEan] = useState<string>("");
  const frmNameRef = useRef(document.createElement('input'));

  const [showPriceAction, setShowPriceAction] = useState<boolean>(false);
  const [openPriceForm, setOpenPriceForm] = useState<boolean>(false);
  const [frmCustomPrice, setFrmCustomPrice] = useState<string>("");
  const frmPriceRef = useRef(document.createElement('input'));

  const [openKeyForm, setOpenKeyForm] = useState<boolean>(false);
  const [frmKeyAuth, setFrmKeyAuth] = useState<string>("");
  const [frmKeyCheck, setFrmKeyCheck] = useState<boolean>(true);
  const frmKeyAuthRef = useRef(document.createElement('input'));

  const [productsCustomPrice, setProductsCustomPrice] = useState<ProductCustomPrice[]>([]);
  const [productsNewCreated, setProductsNewCreated] = useState<any>([]);

  document.addEventListener('keydown', (event) => {
    if (event.key === '*') {
      setShowQtyAction(true);
      setInputEan("");
      setFrmProductEan("");
    }

    if (event.key === 'Delete') {
      setIsMinusProduct(true);
      setAddCartAction(false);
      setInputEan("");
      setFrmProductEan("");
    }

    if (event.key === 'F8') {
      setInputSearch("");
      inputSearchRef.current.focus();
    }

    if (event.key === 'F9') {
      setShowPriceAction(!showPriceAction);
    }
  });

  useEffect(() => {
    const productsStorage = localStorage.getItem('products');
    const cartSubTotalStorage = localStorage.getItem('cart_sub_total');
    const productsCustomPriceStorage = localStorage.getItem('products_custom_price');
    const productsNewCreatedStorage = localStorage.getItem('products_new_created');

    if (productsStorage !== null && productsStorage !== '') {
      setProducts(JSON.parse(productsStorage));
    }
    if (cartSubTotalStorage !== null) {
      setCartSubTotal(parseFloat(cartSubTotalStorage));
    }
    if (productsCustomPriceStorage !== null && productsCustomPriceStorage !== '') {
      setProductsCustomPrice(JSON.parse(productsCustomPriceStorage));
    }
    if (productsNewCreatedStorage !== null && productsNewCreatedStorage !== '') {
      setProductsNewCreated(JSON.parse(productsNewCreatedStorage));
    }
  }, []);

  useEffect(() => {
    if (!addCartAction) {
      setInputEan("");
      setFrmProductEan("");
      setInputQty("");
      setShowQtyAction(false);
      setGotoCartStatus(false);
      inputEanRef.current.focus();
    } else {
      addProductToCart();
    }
  }, [addCartAction]);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    addProductToCart();
  }, [inputQty, product]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (products.length > 0) {
      const cartSubTotal = products.reduce((total, product) => {
        return total + product.total;
      }, 0).toFixed(2);

      setCartSubTotal(parseFloat(cartSubTotal));
      setGotoCartStatus(true);
      localStorage.setItem('cart_total', cartSubTotal);
      localStorage.setItem('cart_sub_total', cartSubTotal);
      localStorage.setItem('products', JSON.stringify(products));
    } else {
      setGotoCartStatus(false);
    }
  }, [products]);

  useEffect(() => {
    if(productsCustomPrice.length > 0) {
      localStorage.setItem('products_custom_price', JSON.stringify(productsCustomPrice));
    } else {
      localStorage.setItem('products_custom_price', '');
    }
  }, [productsCustomPrice]);

  useEffect(() => {
    if(productsNewCreated.length > 0) {
      localStorage.setItem('products_new_created', JSON.stringify(productsNewCreated));
    } else {
      localStorage.setItem('products_new_created', '');
    }
  }, [productsNewCreated]);

  useEffect(() => {
    if(frmKeyAuth !== '') {
      localStorage.setItem('auth_key', frmKeyAuth);
    }
  }, [frmKeyAuth])

  const fetchProduct = async (ean: string) => {
    if (ean !== "") {
      const data = await productAction.getProduct(ean);

      if (data && data.product_id) {
        setProduct(data);
        const beepAudio = new Audio(beepSound);
        beepAudio.play();
      } else {
        const pumAudio = new Audio(pumSound);
        pumAudio.play();
        setOpenForm(true);
        setShowCreateProductAction(true);
        frmNameRef.current.focus();
      }
    } else {
      setProduct(null);
    }
  }

  const fetchProductBySku = async (sku: number, price: number) => {
    if (sku > 0) {
      const data = await productAction.getProductBySku(sku);
      
      if (data && data.product_id) {
        // Producto encontrado, establece el nuevo precio y continúa con el flujo normal
        data.price = price;
        data.special = null;
        setProduct(data);
        const beepAudio = new Audio(beepSound);
        beepAudio.play();
      } else {
        // Producto no encontrado, reproduce pumSound y muestra el formulario para crear producto
        const pumAudio = new Audio(pumSound);
        pumAudio.play();
        setOpenForm(true);
        setShowCreateProductAction(true);
        frmNameRef.current.focus();
      }
    } else {
      setProduct(null);
    }
  }
  

  const addProductToCart = () => {
    let isCheckBySku = false;
    const eanPrefix = inputEan.slice(0, 2);
    if (eanPrefix === '20' || eanPrefix === '21' || eanPrefix === '23')  {
      isCheckBySku = true;
    }
  
    // Solo proceder si se ha establecido la acción de agregar al carrito y 
    // no estamos esperando por la acción de cantidad después de presionar `*`
    if (product !== null && product.product_id && addCartAction && !showQtyAction) {
      let currentProducts = products;
      let currentProductsCustomPrice = productsCustomPrice;
      let addQty = inputQty !== "" ? parseInt(inputQty, 10) : 1;
  
      if (addQty > 0) {
        let addPrice = product.special ? parseFloat(product.special) : parseFloat(product.price);
        
        // Genera una 'key' única para productos con el mismo SKU pero diferentes precios
        // Nota: Asegúrate de que la lógica aquí cubra tus necesidades específicas para el SKU y precio
        let pKey = isCheckBySku ? `${product.product_id}_${eanPrefix}_${addQty}_${addPrice}` : product.product_id;
  
        const productIndex = currentProducts.findIndex(pro => pro.key === pKey);
  
        if (productIndex !== -1) {
          // El producto ya está en el carrito, actualiza la cantidad y el total
          currentProducts[productIndex].quantity += addQty;
          currentProducts[productIndex].total = currentProducts[productIndex].quantity * addPrice;
        } else {
          // Nuevo producto, agrégalo al carrito
          const newProduct = {
            key: pKey,
            id: product.product_id,
            sku: product.sku,
            quantity: addQty,
            name: product.name,
            description: `${product.name} ${product.ean}`,
            price: addPrice.toFixed(2),
            total: addQty * addPrice,
          };
          currentProducts = [newProduct, ...currentProducts];
          if (isCheckBySku) {
            // Guarda el precio personalizado si es necesario
            currentProductsCustomPrice.push({
              id: newProduct.id,
              price: addPrice,
              quantity: addQty,
            });
          }
        }
  
        setProducts([...currentProducts]);
        setProductsCustomPrice([...currentProductsCustomPrice]);
        setAddCartAction(false); // Resetear la acción de agregar al carrito
        setShowPriceAction(false);
        setShowCreateProductAction(false);
        setInputQty(""); // Resetear la cantidad después de agregar el producto
      }
    }
  
    if (product !== null && product.product_id && isMinusProduct) {
      let currentProducts = products;
  
      let addPrice = parseFloat(product.price).toFixed(2);
      if (product.special) {
        addPrice = parseFloat(product.special).toFixed(2);
      }
  
      const productIndex = currentProducts.findIndex(pro => pro.id === product.product_id);
  
      if (productIndex !== -1) {
        const newQty = currentProducts[productIndex].quantity - 1;
  
        if (newQty > 0) {
          currentProducts[productIndex].quantity = newQty;
          currentProducts[productIndex].total = newQty * parseFloat(addPrice);
          setProducts([...currentProducts]);
        } else {
          // Remueve el producto si la cantidad después de decrementar es 0
          currentProducts.splice(productIndex, 1);
          setProducts([...currentProducts]);
        }
      }
  
      setIsMinusProduct(false); // Resetear la acción de decrementar producto
    }
  };
  
  const handleSetInputEan = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    if (value === '*') {
      setInputEan("");
      setFrmProductEan("");
    } else if (value.length <= 13) {
      setInputEan(value);
      setFrmProductEan(value);
    }
  };
  
  
  const handleEanKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      setProduct(null);
      if (inputEan !== "") {
        const eanLength = inputEan.length;
        const eanPrefix = inputEan.slice(0, 2);
  
        if (eanPrefix === '20' || eanPrefix === '21') {
          const eanSkuW = parseInt(inputEan.slice(2, 5));
          const eanPriceW = parseFloat(inputEan.slice(5, eanLength - 1)) / 100;
          fetchProductBySku(eanSkuW, eanPriceW);
          if (!showQtyAction && !isMinusProduct) {
            setAddCartAction(true);
          }
        } else if (eanPrefix === '23') {
          const sku = parseInt(inputEan.slice(2, 5));
          const price = parseFloat(inputEan.slice(5, eanLength - 1)) / 100;
          const verifier = inputEan.slice(eanLength - 1); // Esto es el dígito verificador
          fetchProductBySku(sku, price);
          if (!showQtyAction && !isMinusProduct) {
            setAddCartAction(true);
          }
        } else {
          fetchProduct(inputEan);
          if (!showPriceAction) {
            if (!showQtyAction && !isMinusProduct) {
              setAddCartAction(true);
            }
          } else {
            setOpenPriceForm(true);
          }
        }
      } else {
        setInputQty("");
      }
  
      if (inputSearch !== "") {
        fetchProduct(inputSearch);
        inputEanRef.current.focus();
      }
    }
  }
  
  const handleSetCustomPrice = (e: any) => {
    if (e.key === 'Enter') {
      if(product) {
        product.price = parseFloat(frmCustomPrice);
        product.special = null;

        setProduct(product);
        setOpenPriceForm(false);
        setFrmCustomPrice("");
        setOpenKeyForm(true);
      }
    }
  }

  const handleAccessKeyAuth = async (e: any) => {
    if (e.key === 'Enter') {
      const keyAuthRes = await productAction.getKeyAuthToEdit(frmKeyAuth);
      if(keyAuthRes) {
        if(showPriceAction) {
          setFrmKeyCheck(true);
          setAddCartAction(true);
          setOpenKeyForm(false);
          setFrmKeyAuth("");
        }
        
        if(showCreateProductAction) {
          setFrmKeyCheck(true);
          setOpenKeyForm(false);
          setFrmKeyAuth("");
          await handleCreateProduct();
        }
      } else {
        setFrmKeyCheck(false);
        setFrmKeyAuth("");
      }
    }
  }

  const handleQtyKeyDown = (e: any) => {
    if (e.key === 'Enter' && showQtyAction) {
        if (inputQty !== "") {
            setShowQtyAction(false); // Restablecer después de agregar la cantidad
            setAddCartAction(true); // Continuar para agregar al carrito
        }
    }
  };

  const handleGoToCart = () => {
    if (gotoCartStatus) {
      window.location.href = '/cart';
    }
  }

  const handleCreateProduct = async () => {
    const inputPrice = parseFloat(frmProductPrice);
    const inputQuantity = parseInt(frmProductQuantity);

    if (inputPrice > 0 && inputQuantity > 0) {
      const payload = {
        name: frmProductName,
        ean: frmProductEan,
        price: inputPrice,
        quantity: inputQuantity
      };

      // Create and get new product data
      const data = await productAction.createProduct(payload);

      if (data && data.product_id) {
        data.quantity = inputQuantity;
        setProduct(data);
        let currentProductsNewCreated = productsNewCreated;
        setProductsNewCreated([data, ...currentProductsNewCreated]);

        const beepAudio = new Audio(beepSound);
        beepAudio.play();
      } else {
        const pumAudio = new Audio(pumSound);
        pumAudio.play();
      }

      setOpenForm(false);
      setFrmProductName("");
      setFrmProductPrice("");
      setFrmProductQuantity("");
    } else {
      alert('Ups! tenemos un error en la cantidad o el precio revisar');
    }
  }

  const handleCloseForm = () => {
    setOpenForm(false);
    setFrmProductName("");
    setFrmProductPrice("");
    setFrmProductQuantity("");
    setShowCreateProductAction(false);
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <LogoutButton />
      <main className={classes.content}>
        <Grid container>
          <Grid item xs={12} className="t_align_center">
            <img src={logo} alt={t("common.main_title")} />
          </Grid>
          <Grid item xs={12} className="search_box">
            <img src={iconSearchF8} width="57" alt="" />
            <TextField
              autoFocus={true}
              inputRef={inputSearchRef}
              className="txt_search"
              value={inputSearch}
              variant="outlined"
              onChange={(e) => setInputSearch(e.target.value)}
              onKeyDown={(e) => handleEanKeyDown(e)}
            />
          </Grid>
          <Grid container className="scanner_page has_box_shadow">
            <Grid item xs={6} className="scanner_part">
              <Grid container>
                <Grid item xs={12} className="part_title t_align_center">
                  <h2>Escáner para buscar producto</h2>
                </Grid>
                <Grid container className="barcode_container">
                  <img className="img_barcode" src={barcode_sample} alt="" />
                  <TextField
                    autoFocus={true}
                    inputRef={inputEanRef}
                    className="txt_ean"
                    value={inputEan}
                    label="EAN"
                    variant="outlined"
                    onChange={(e) => handleSetInputEan(e)}
                    onKeyDown={(e) => handleEanKeyDown(e)}
                  />
                </Grid>
                <Grid item xs={12} className="iconDown t_align_center">
                  <ArrowDownwardIcon />
                </Grid>
                <Grid container>
                  <Grid item xs={12} className="part_title t_align_center">
                    <h2>Información del Producto</h2>
                  </Grid>
                  {(product && product.product_id) ? (
                    <Grid container className="product_result">
                      <Grid item xs={8} className="product_content">
                        <p>Product ID - <span>{product.product_id}</span></p>
                        <p>EAN - <span>{product.ean}</span></p>
                        <p>Descripción - <span>{product.name}</span></p>
                        <p>Precio - <span>${parseFloat(product.price)}</span></p>
                        {product.special && (
                          <p>Oferta - <span>${parseFloat(product.special)}</span></p>
                        )}
                      </Grid>
                      {showQtyAction && (
                        <Grid item xs={4}>
                          <p><strong>Cantidad</strong></p>
                          <input
                            type="text"
                            className="txt_qty"
                            ref={inputQtyRef}
                            value={inputQty}
                            onChange={(e) => setInputQty(e.target.value)}
                            onKeyDown={(e) => handleQtyKeyDown(e)}
                          />
                        </Grid>
                      )}
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <p className="p_empty t_align_center">¡Producto no escaneado!</p>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} className="product_list_part">
              <Grid container>
                <Grid item xs={12} className="part_title t_align_center">
                  <h2>Productos</h2>
                </Grid>
                <ProductList products={products} />
                <Grid item xs={12} className="total_info">
                  <p className="total_text">Total - ${cartSubTotal}</p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </main>
      <Footer
        backAction={() => history.push('/')}
        nextAction={() => handleGoToCart()}
      />
      <Modal
        open={openForm}
        onClose={handleCloseForm}
      >
        <div className="modal_product_form">
          <h2>Creador de productos</h2>
          <div className="form-content">
            <div className="form-group">
              <TextField
                className="form-input"
                value={frmProductEan}
                disabled
                label="EAN"
                variant="outlined"
              />
            </div>
            <div className="form-group">
              <TextField
                className="form-input"
                autoFocus={true}
                value={frmProductName}
                onChange={(e) => setFrmProductName(e.target.value)}
                label="Descripción Corta"
                inputRef={frmNameRef}
                variant="outlined"
              />
            </div>
            <div className="form-group">
              <TextField
                className="form-input"
                value={frmProductPrice}
                onChange={(e) => setFrmProductPrice(e.target.value)}
                label="Precio"
                variant="outlined"
              />
            </div>
            <div className="form-group">
              <TextField
                className="form-input"
                value={frmProductQuantity}
                onChange={(e) => setFrmProductQuantity(e.target.value)}
                label="Cantidad"
                variant="outlined"
              />
            </div>
            <Button variant="contained" color="primary" onClick={() => setOpenKeyForm(true)}>Guardar</Button>
          </div>
        </div>
      </Modal>
      {product && (
        <Modal open={openPriceForm}>
          <div className="modal_product_form modal_price_form">
            <h2>Cambia el precio: {product.name}</h2>
            <div className="form-content">
              <div className="form-group">
                <TextField
                  className="form-input"
                  value={frmCustomPrice}
                  label="Precio"
                  variant="outlined"
                  autoFocus={true}
                  inputRef={frmPriceRef}
                  onChange={(e) => setFrmCustomPrice(e.target.value)}
                  onKeyDown={handleSetCustomPrice}
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
      <Modal open={openKeyForm}>
        <div className="modal_product_form modal_price_form">
          <h2>Ingresa la clave de autenticación</h2>
          <div className="form-content">
            <div className="form-group">
              <TextField
                className="form-input"
                type="password"
                value={frmKeyAuth}
                label="Authentication Key"
                variant="outlined"
                autoFocus={true}
                inputRef={frmKeyAuthRef}
                onChange={(e) => setFrmKeyAuth(e.target.value)}
                onKeyDown={handleAccessKeyAuth}
              />
              {!frmKeyCheck && (
                <p className="key-error">* Ups! Clave de autenticación incorrecta para editar.</p>
              )}
            </div>
          </div>
        </div>
      </Modal>
      {showPriceAction && (
        <div className="custom-price-mode">MODO DE PRECIO PERSONALIZADO: <b>ON</b></div>
      )}
    </div>
  )
}